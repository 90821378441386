import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";

class ShopInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      token: getLocalStorageItem("myStylistAdmin")?.token || "",
      working_hours: [
        {
          sunday: { open: "", from: "", to: "" },
          monday: { open: "", from: "", to: "" },
          tuesday: { open: "", from: "", to: "" },
          wednesday: { open: "", from: "", to: "" },
          thursday: { open: "", from: "", to: "" },
          friday: { open: "", from: "", to: "" },
          saturday: { open: "", from: "", to: "" },
        },
      ],
      successMsg: "",
      errorMsg: "",
      errors: "",
      redirect: false,
    };
    this.daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
  }

  handleWorkingHoursChange = (e, group, index) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedGroup = [...prevState[group]];
      const day = name.split(".")[0];
      const field = name.split(".")[1];

      if (field === "open" && value === "No") {
        updatedGroup[index][day].from = "";
        updatedGroup[index][day].to = "";
      }

      updatedGroup[index][day][field] = value;

      return { [group]: updatedGroup };
    });
  };

  getUserResults = async (userid) => {
    try {
      const { token } = this.state;
      const { AdminExpertUserDetails } = adminendPoints;
      const expertUrl = `${AdminExpertUserDetails}`;
      const contentType = "application/json";
      const body = {
        userid,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );
      const { status, user } = response.data;

      if (user) {
        const userWorkingHours = user.working_hours && user.working_hours[0];

        const workingHoursState = userWorkingHours
          ? {
              sunday: {
                open: user.working_hours[0].sunday.open,
                from: user.working_hours[0].sunday.from,
                to: user.working_hours[0].sunday.to,
              },
              monday: {
                open: user.working_hours[0].monday.open,
                from: user.working_hours[0].monday.from,
                to: user.working_hours[0].monday.to,
              },
              tuesday: {
                open: user.working_hours[0].tuesday.open,
                from: user.working_hours[0].tuesday.from,
                to: user.working_hours[0].tuesday.to,
              },
              wednesday: {
                open: user.working_hours[0].wednesday.open,
                from: user.working_hours[0].wednesday.from,
                to: user.working_hours[0].wednesday.to,
              },
              thursday: {
                open: user.working_hours[0].thursday.open,
                from: user.working_hours[0].thursday.from,
                to: user.working_hours[0].thursday.to,
              },
              friday: {
                open: user.working_hours[0].friday.open,
                from: user.working_hours[0].friday.from,
                to: user.working_hours[0].friday.to,
              },
              saturday: {
                open: user.working_hours[0].saturday.open,
                from: user.working_hours[0].saturday.from,
                to: user.working_hours[0].saturday.to,
              },
            }
          : {
              sunday: { open: "", from: "", to: "" },
              monday: { open: "", from: "", to: "" },
              tuesday: { open: "", from: "", to: "" },
              wednesday: { open: "", from: "", to: "" },
              thursday: { open: "", from: "", to: "" },
              friday: { open: "", from: "", to: "" },
              saturday: { open: "", from: "", to: "" },
            };

        this.setState({
          id: userid,
          working_hours: [workingHoursState],
        });
      } else {
        this.setState({
          id: user._id,
          errorMsg: "Failed to create City. Please try again.",
          successMsg: "",
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  validateForm = () => {
    const { working_hours } = this.state;

    const errors = {};

    const workingHoursErrors = {};

    this.daysOfWeek.forEach((day) => {
      if (
        working_hours[0][day.toLowerCase()].open === "Yes" &&
        (!working_hours[0][day.toLowerCase()].from ||
          !working_hours[0][day.toLowerCase()].to)
      ) {
        workingHoursErrors[day.toLowerCase()] =
          "From and To fields are required when the shop is open";
      }
    });

    Object.assign(errors, workingHoursErrors);

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const { working_hours, id, token } = this.state;

      const body = {
        id,
        working_hours,
      };

      const { AdminUpdateShopInformation } = adminendPoints;
      const method = "POST";

      const contentType = "application/json";
      try {
        const response = await APICaller(
          AdminUpdateShopInformation,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Shop Information Updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
              }, 1000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This District name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create State. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  render() {
    const { errors, working_hours, redirect, successMsg, errorMsg, token, id } =
      this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">
                      Amenities And Others Information
                    </h4>
                  </div>
                  <div className="ms-auto">
                    <Link to={`/EditExpert/${id}`} className="btn-light1 ms-2">
                      <i className="fa-solid fa-arrow-left-long me-2"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="">
                    {errorMsg && (
                      <div className="alert alert-danger" role="alert">
                        {errorMsg}
                      </div>
                    )}
                    {successMsg && (
                      <div className="alert alert-success" role="alert">
                        {successMsg}
                      </div>
                    )}

                    {/* {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )} */}
                    <div className="row">
                      <div className="col-lg-12">
                        <h5>Shop Information</h5>
                        <hr />
                        <div className="row">
                          {this.daysOfWeek.map((day, index) => (
                            <div className="col-lg-12 mb-2" key={index}>
                              <h5>{day}</h5>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Shop is open?</label>
                                    <select
                                      name={`${day.toLowerCase()}.open`}
                                      className={`form-select ${
                                        errors[day.toLowerCase()]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      value={
                                        working_hours[0][day.toLowerCase()].open
                                      }
                                      onChange={(e) =>
                                        this.handleWorkingHoursChange(
                                          e,
                                          "working_hours",
                                          0
                                        )
                                      }
                                    >
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                    {errors[day.toLowerCase()] && (
                                      <div className="invalid-feedback">
                                        {errors[day.toLowerCase()]}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {working_hours[0][day.toLowerCase()].open ===
                                  "Yes" && (
                                  <>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label>From</label>
                                        <input
                                          type="time"
                                          className={`form-control ${
                                            errors[day.toLowerCase() + "_from"]
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name={`${day.toLowerCase()}.from`}
                                          value={
                                            this.state.working_hours[0][
                                              day.toLowerCase()
                                            ].from
                                          }
                                          onChange={(e) =>
                                            this.handleWorkingHoursChange(
                                              e,
                                              "working_hours",
                                              0
                                            )
                                          }
                                        />
                                        {errors[
                                          day.toLowerCase() + "_from"
                                        ] && (
                                          <div className="invalid-feedback">
                                            {
                                              errors[
                                                day.toLowerCase() + "_from"
                                              ]
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label>To</label>
                                        <input
                                          type="time"
                                          className={`form-control ${
                                            errors[day.toLowerCase() + "_to"]
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name={`${day.toLowerCase()}.to`}
                                          value={
                                            this.state.working_hours[0][
                                              day.toLowerCase()
                                            ].to
                                          }
                                          onChange={(e) =>
                                            this.handleWorkingHoursChange(
                                              e,
                                              "working_hours",
                                              0
                                            )
                                          }
                                        />
                                        {errors[day.toLowerCase() + "_to"] && (
                                          <div className="invalid-feedback">
                                            {errors[day.toLowerCase() + "_to"]}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(ShopInformation);
