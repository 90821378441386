import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

import ViewPackages from "./viewPackages";

export default class Packages extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      showConfirmation: false,
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      successMsg: "",
      errorMsg: "",
      activeTab: "All",
      search: "",
      sort: "",
      isVisibleView: false,
      isVisibleAdd: false,
    };
  }

  componentDidMount() {
    this.getAllPackage();
  }

  getAllPackage = () => {
    const { token, currentPage, itemsPerPage } = this.state;
    const { AdminPackageList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    const url = `${AdminPackageList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, packages, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: packages,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getAllFaq();
    });
  };

  handleDelete = async (itemId) => {
    const { token } = this.state;
    const { AdminFaqDelete } = adminendPoints;
    const method = "POST";

    const body = {
      itemId,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        AdminFaqDelete,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;
      if (status === 200) {
        this.setState(
          (prevState) => ({
            results: prevState.results.filter((item) => item._id !== itemId),
            successMsg: "Faq deleted successfully.",
            errorMsg: "",
          }),
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "" }); // Clear the success message after 3 seconds
            }, 3000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to delete FAQ. Please try again.",
            successMsg: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while creating the category. Please try again.",
        successMsg: "",
      });
    }
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getCategories();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleCloseViewContent = () => {
    this.setState({ isVisibleView: false });
  };

  viewCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  handleCloseAddContent = () => {
    this.setState({ isVisibleAdd: false });
  };

  addCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleAdd: !prevState.isVisibleAdd,
    }));
  };

  handleToggleCheckBox = async (packageId, currentStatus) => {
    const { token } = this.state;
    const { AdminPackageStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      packageId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminPackageStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((offer) =>
            offer._id === packageId ? { ...offer, status: newStatus } : offer
          ),
          successMsg: `Offer Status is ${newStatus}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
      } else {
        console.error("Failed to update offer status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      successMsg,
      errorMsg,
      activeTab,
      sort,
      isVisibleView,
      isVisibleAdd,
    } = this.state;



    if (token === "") {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
        .filter((item) => {
          const matchesSearch =
            item.package_name.toLowerCase().includes(search.toLowerCase());

          return matchesSearch;
        })
        .sort((a, b) => {
          return this.state.sort === "asc"
            ? a.package_name.localeCompare(b.package_name)
            : b.package_name.localeCompare(a.package_name);
        })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);


    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Packages List
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">All Packages List</h4>
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-light1 ms-2"
                      onClick={this.viewCategorytoggle}
                    >
                      <i className="fa-solid fa-file-export me-2"></i>View
                      Package
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Package Name</th>
                      <th>Service Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((packageData, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{packageData.package_name}</td>
                          <td>
                            {packageData.service_name.map((service, index) => (
                              <div key={index}>
                                <strong>{service.service_name}</strong>
                                {service.sub_services.length > 0 && (
                                  <ul>
                                    {service.sub_services.map((subService, subIndex) => (
                                      <li key={subIndex}>{subService.sub_service_name}</li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            ))}
                          </td>
                          <td>{formatDate(packageData.start_date)}</td>
                          <td>{formatDate(packageData.end_date)}</td>
                          <td className="text-center">
                            {packageData.status && (
                              <div className="form-check form-switch d-inline-block p-0 m-0">
                                <input
                                  className="form-check-input m-0"
                                  type="checkbox"
                                  role="switch"
                                  checked={packageData.status === "Active"}
                                  onChange={() =>
                                    this.handleToggleCheckBox(
                                      packageData._id,
                                      packageData.status
                                    )
                                  }
                                />
                              </div>
                            )}
                          </td>

                          <td className="text-center">
                            <a href="#" className="icon-btn">
                              <img
                                src="assets/img/icon-edit.svg"
                                alt=""
                                title=""
                              />
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ViewPackages
          isVisibleView={isVisibleView}
          handleCloseViewContent={this.handleCloseViewContent}
        />
      </div>
    );
  }
}
