import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class ViewDistrict extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      stateResults: [],
      emailErrorMsg: "",
    };
  }

  componentDidMount() {
    this.getDistrictResults();
    this.getStateResults();
  }

  getStateName = (stateId) => {
    const { stateResults } = this.state;
    const state = stateResults.find((item) => item._id === stateId);
    return state ? state.state_name : "N/A"; // Replace "N/A" with a default value or message if state name is not found
  };

  getStateResults = () => {
    const { token } = this.state;

    const { AdminStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminStateName}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            stateResults: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getDistrictResults = () => {
    const { searchDistrict, searchStatus, token, currentPage, itemsPerPage } =
      this.state;

    const { AdminDistrictList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    if (searchDistrict) {
      queryParams += `&district_name=${searchDistrict}`;
    }
    if (searchStatus) {
      queryParams += queryParams
        ? `&status=${searchStatus}`
        : `status=${searchStatus}`;
    }

    const url = `${AdminDistrictList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, districts, totalItems } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: districts,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  render() {
    const { results, token } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />

        <div className="content-wrapper">
          <section className="content">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header card-header-tool-align">
                  <h3 className="card-title">District List</h3>
                  <div className="card-tools">
                    <Link
                      to="/ManageDistrict"
                      className="btn btn-sm btn-success"
                    >
                      Manage District
                    </Link>
                  </div>
                </div>

                <div className="card-body p-0">
                  <table className="table table-striped projects">
                    <thead>
                      <tr>
                        <th>State Name</th>
                        <th>District Name</th>
                        <th>Created Date</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No records found.
                          </td>
                        </tr>
                      ) : (
                        results.map((item) => (
                          <tr key={item.id}>
                            <td>{this.getStateName(item.state_id)}</td>
                            <td>{item.district_name}</td>
                            <td>{item.createdAt}</td>
                            <td className="project-state">
                              {item.status === "Active" ? (
                                <span className="badge badge-success">
                                  {item.status}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {item.status}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(ViewDistrict);
