import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class AddOfferForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedServices: [],
      selectedSubServices: [],
      selectedStates: [],
      selectedDistricts: [],
      selectedCities: [],
      selectedExperts: [],
      states: [],
      districts: [],
      cities: [],
      services: [],
      subServices: [],
      experts: [],
      successMsg: "",
      errorMsg: "",
      offer_name: "",
      number_of_offers: "",
      startDate: "",
      endDate: "",
      additional_information: "",
      featured_image: "",
      selectedFile: null,
      previewURL: null,
      status: "",
      discount: "",
      errors: {}, // Initialize errors object here
    };
  }

  componentDidMount() {
    this.getAllUsersResults();
  }

  getAllUsersResults = async () => {
    try {
      const { token } = this.state;
      const {
        AdminStateList,
        AdminDistrictList,
        AdminCityList,
        AdminServicesListMobile,
        AdminExpertList,
      } = adminendPoints;

      const method = "GET";
      const contentType = "application/json";
      const urls = {
        stateUrl: `${AdminStateList}`,
        districtUrl: `${AdminDistrictList}`,
        cityUrl: `${AdminCityList}`,
        serviceUrl: `${AdminServicesListMobile}`,
        expertUrl: `${AdminExpertList}`,
      };

      const fetchAllData = async () => {
        const data = {};
        await Promise.all(
          Object.keys(urls).map(async (key) => {
            const response = await APICaller(
              urls[key],
              method,
              "",
              contentType,
              token
            );
            data[key] = response.data;
          })
        );
        return data;
      };

      const { stateUrl, districtUrl, cityUrl, serviceUrl, expertUrl } =
        await fetchAllData();

      this.setState({
        states: stateUrl.states,
        districts: districtUrl.districts,
        cities: cityUrl.citys,
        services: serviceUrl.services,
        experts: expertUrl.expertusers,
      });
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({ errorMsg: "Error fetching data" });
    }
  };

  handleServiceChange = async (selectedOptions) => {
    this.setState({ selectedServices: selectedOptions });

    const selectedServiceIds = selectedOptions.map((service) => service.value);

    try {
      const { token } = this.state;
      const { AdminSubServicesListMobile } = adminendPoints;
      const method = "POST";
      const contentType = "application/json";

      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        { serviceIds: selectedServiceIds.join(",") },
        contentType,
        token
      );

      const { status, subServices } = response.data;

      if (status === 200) {
        this.setState({
          subServices: (subServices || []).map((subService) => ({
            value: subService._id,
            label: subService.sub_service_name,
          })),
          selectedSubServices: [],
        });
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log("error fetching sub-services --> ", error);
    }
  };

  handleStateChange = (selectedOptions) => {
    this.setState({
      selectedStates: selectedOptions,
      selectedDistricts: [],
      selectedCities: [],
    });
  };

  handleDistrictChange = (selectedOptions) => {
    this.setState({ selectedDistricts: selectedOptions, selectedCities: [] });
  };

  handleCityChange = (selectedOptions) => {
    this.setState({ selectedCities: selectedOptions });
  };

  handleExpertChange = (selectedOptions) => {
    this.setState({ selectedExperts: selectedOptions });
  };

  validateForm = () => {
    const {
      expert_id,
      offer_name,
      service_name,
      number_of_offers,
      startDate,
      endDate,
      additional_information,
      featured_image,
      status,
      discount,
    } = this.state;

    const newErrors = {};

    if (!expert_id) {
      newErrors.expert_id = "Expert ID is required.";
    }

    if (!offer_name) {
      newErrors.offer_name = "Offer Name is required.";
    }

    if (!service_name || !service_name.length) {
      newErrors.service_name = "At least one service is required.";
    } else {
      service_name.forEach((service, index) => {
        if (!service.service_id) {
          newErrors[`service_name_${index}`] = "Service ID is required.";
        }
        if (!service.service_name) {
          newErrors[`service_name_${index}`] = "Service Name is required.";
        }
        if (!service.sub_services || !service.sub_services.length) {
          newErrors[`sub_services_${index}`] =
            "At least one sub-service is required.";
        } else {
          service.sub_services.forEach((subService, subIndex) => {
            if (!subService.sub_service_id) {
              newErrors[`sub_service_id_${index}_${subIndex}`] =
                "Sub-service ID is required.";
            }
            if (!subService.sub_service_name) {
              newErrors[`sub_service_name_${index}_${subIndex}`] =
                "Sub-service Name is required.";
            }
          });
        }
      });
    }

    if (!number_of_offers) {
      newErrors.number_of_offers = "Number of offers is required.";
    }

    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End date is required.";
    }

    if (!additional_information) {
      newErrors.additional_information = "Additional information is required.";
    }

    if (!featured_image) {
      newErrors.featured_image = "Featured image is required.";
    }

    if (!status) {
      newErrors.status = "Status is required.";
    }

    if (!discount) {
      newErrors.discount = "Discount is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { category_name, selectedFile, token, id, status } = this.state;

      const { AdminEditCategory } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("category_name", category_name);
      formData.append("status", status);
      formData.append("fileName", selectedFile);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminEditCategory,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Category updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This category name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      selectedServices,
      selectedSubServices,
      selectedStates,
      selectedDistricts,
      selectedCities,
      selectedExperts,
      states,
      districts,
      cities,
      services,
      subServices,
      experts,
      successMsg,
      errorMsg,
      token,
      offer_name,
      startDate,
      endDate,
      number_of_offers,
      discount,
      additional_information,
      previewURL,
      errors,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const serviceOptions = services.map((service) => ({
      value: service._id,
      label: service.service_name,
    }));

    const subServiceOptions = subServices.map((subService) => ({
      value: subService.value,
      label: subService.label,
    }));

    const expertOptions = experts.map((expert) => ({
      value: expert._id,
      label: expert.name,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Add Offer Information</h4>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageOffers" className="link">
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alert!
                    </h5>
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5>
                      <i className="icon fas fa-check"></i> Alert!
                    </h5>
                    {successMsg}
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Service</label>
                    <Select
                      id="service"
                      options={serviceOptions}
                      value={selectedServices}
                      isMulti
                      onChange={this.handleServiceChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="service">Sub Service</label>
                  <Select
                    id="subServices"
                    options={subServiceOptions}
                    isMulti
                    value={selectedSubServices}
                    onChange={(selectedOptions) =>
                      this.setState({ selectedSubServices: selectedOptions })
                    }
                  />
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select State</label>
                    <Select
                      value={selectedStates}
                      onChange={this.handleStateChange}
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select District</label>
                    <Select
                      value={selectedDistricts}
                      onChange={this.handleDistrictChange}
                      options={districts.map((district) => ({
                        value: district._id,
                        label: district.district_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select City</label>
                    <Select
                      value={selectedCities}
                      onChange={this.handleCityChange}
                      options={cities.map((city) => ({
                        value: city._id,
                        label: city.city_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Expert</label>
                    <Select
                      value={selectedExperts}
                      onChange={this.handleExpertChange}
                      options={expertOptions}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="offer_name">Offer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="offer_name"
                    placeholder="Offer Name"
                    name="offer_name"
                    onChange={this.handleInputChange}
                  />
                  {errors.offer_name && (
                    <p className="error">{errors.offer_name}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="number_of_offers"> Number of Offers </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_offers"
                    placeholder="Number of Offers"
                    name="number_of_offers"
                    value={number_of_offers}
                    onChange={this.handleInputChange}
                  />
                  {errors.number_of_offers && (
                    <p className="error">{errors.number_of_offers}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    name="discount"
                    value={discount}
                    onChange={this.handleInputChange}
                  />
                  {errors.discount && (
                    <p className="error">{errors.discount}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="additional_information">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_information"
                      placeholder="Additional Information"
                      name="additional_information"
                      value={additional_information}
                      onChange={this.handleInputChange}
                    />
                    {errors.additional_information && (
                      <p className="error">{errors.additional_information}</p>
                    )}
                  </div>
                </div>

                <div className="col">
                  <div className="upload-img-box">
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <p className="mb-0">Upload Image</p>
                    <input
                      type="file"
                      className="form-control"
                      id="fileName"
                      placeholder="Absence through"
                      name="fileName"
                      accept="image/*"
                      onChange={this.handleFileSelect}
                    />
                    {previewURL && (
                      <div>
                        <img
                          src={previewURL}
                          alt="Category Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button type="button" onClick={this.handleFileRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Add Offer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddOfferForm;
