import {combineReducers} from 'redux';
import admin from './admin';
const appReducer = combineReducers({
  admin,
});

const rootReducer = (state, action) => {
  if (action.type === 'ADMIN_LOGOUT') {
    console.log('admin logout');
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
