import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationVisible: false,
    };
  }

  handleLinkClick = () => {
    this.setState((prevState) => ({
      notificationVisible: !prevState.notificationVisible,
    }));
  };

  render() {
    return (
      <div className="top-navbar">
        <div className="container-fluid">
          <div className="top-navbar-inner">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="top-navbar-left">
                  <p className="mb-0">
                    Welcome Back,
                    <img
                      src="assets/img/icon-wave.svg"
                      alt="Wave icon"
                      title="Wave icon"
                    />
                  </p>
                  <p className="mb-0">
                    <strong>Elisha Atif</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                <div className="d-inline-block align-top mx-1 top-navbar-notification">
                  <Link to="#" onClick={this.handleLinkClick}>
                    <img
                      src="assets/img/icon-notification.svg"
                      alt="Notification icon"
                      title="Notification icon"
                    />
                  </Link>

                  {this.state.notificationVisible && (
                    <div className="card notifications-box">
                      <div className="card-body">
                        <div className="notifications-header">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="mb-0">Notifications</h4>
                            <div className="dropdown notifications-dropdown-v">
                              <Link
                                to="#"
                                className="dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Action
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Another action
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Something else here
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="notifications-tabs d-flex align-items-center justify-content-between mb-3">
                            <Link to="#" className="active">
                              All
                            </Link>
                            <Link to="#">Offer</Link>
                            <Link to="#">Promotion</Link>
                            <Link to="#">Appointment</Link>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <p className="tc-5 mb-0">
                              <i className="fa-solid fa-bell me-2"></i>All Notifications
                            </p>
                            <p className="tc-5 mb-0">
                              <i className="fa-solid fa-check-double me-2"></i>Mark all
                              as read
                            </p>
                          </div>
                        </div>
                        <div className="notifications-body">
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                            <div className="me-3">
                              <div className="d-flex">
                                <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                  <img
                                    src="assets/img/dummy-user.png"
                                    alt=""
                                    title=""
                                  />
                                </figure>
                                <div className="flex-grow-1">
                                  <p className="mb-1 tc-5">
                                    Your appointment has been successfully schedule with{" "}
                                    <strong className="tc-6 fw-medium">
                                      Nickson John
                                    </strong>
                                  </p>
                                  <p className="tc-7 mb-0">1 hr ago</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="dropdown notifications-dropdown-h">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </Link>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Another action
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to="#">
                                      Something else here
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-inline-block align-top mx-1 top-navbar-setting">
                  <Link to="#">
                    <img
                      src="assets/img/icon-setting.svg"
                      alt="Settings icon"
                      title="Settings icon"
                    />
                  </Link>
                </div>

                <div className="dropdown d-inline-block align-top ms-1 top-navbar-user">
                  <Link
                    className="dropdown-toggle d-flex align-items-center text-start"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <figure className="me-2 mb-0 top-navbar-user-img">
                      <img
                        src="assets/img/dummy-user.png"
                        alt="User avatar"
                        title="User avatar"
                      />
                    </figure>
                    <div>
                      <strong className="d-block top-navbar-user-name">
                        Elisha Atif
                      </strong>
                      <span className="d-block top-navbar-user-email">
                        elishaatif456@gmail.com
                      </span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="#">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
