import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import slugify from "slugify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class AddCMSPage extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      title: "",
      content: "",
      slug: "",
      editorHtml: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      stateResults: [],
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  validateForm = () => {
    const { title, content, slug } = this.state;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Page title is required.";
    }

    if (!content) {
      newErrors.content = "Content is required.";
    }

    if (!slug) {
      newErrors.slug = "Slug is required.";
    }

    return newErrors;
  };

  handleEditorChange = (html) => {
    this.setState({ editorHtml: html, content: html }); // Set content state
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { title, content, slug, token } = this.state;

      const { AdminAddCMS } = adminendPoints;
      const method = "POST";
      const body = {
        title,
        content,
        slug,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminAddCMS,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "CMS page created successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This CMS page is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create CMS page. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      const slug = slugify(value, { lower: true }); // Convert to lowercase
      this.setState({ [name]: value, slug }); // Update both title and slug
    } else {
      this.setState({ [name]: value });
    }
  };
  render() {
    const {
      title,
      content,
      slug,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      editorHtml,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add CMS Information</h4>
                    <p className="mb-0 tc-5">Add CMS</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                      <h5>
                        <i className="icon fas fa-ban"></i> Alert!
                      </h5>
                      {errorMsg}
                    </div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                      <h5>
                        <i className="icon fas fa-check"></i> Alert!
                      </h5>
                      {successMsg}
                    </div>
                  )}

                  {errors && (
                    <div className="callout callout-danger">
                      <h5>All * fields are required</h5>
                      {errors.title && <p className="error">{errors.title}</p>}
                      {errors.slug && <p className="error">{errors.slug}</p>}
                      {errors.content && (
                        <p className="error">{errors.content}</p>
                      )}
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-6">
                      <label htmlFor="FaqTitle" className="form-label">
                        Page Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Page Title"
                        name="title"
                        value={title}
                        onChange={this.handleInputChange}
                      />
                      {errors.title && <p className="error">{errors.title}</p>}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="FaqAnswer" className="form-label">
                        Page Slug
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Slug"
                        placeholder="Page Slug"
                        name="Slug"
                        value={slug}
                        onChange={this.handleInputChange}
                      />
                      {errors.slug && <p className="error">{errors.slug}</p>}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="FaqAnswer" className="form-label">
                        Page Content
                      </label>
                      <ReactQuill
                        name="content"
                        value={editorHtml}
                        onChange={this.handleEditorChange}
                        className="quill-editor"
                        style={{ minHeight: "200px" }}
                      />
                      {errors.editorHtml && (
                        <p className="error">{errors.editorHtml}</p>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(AddCMSPage);
