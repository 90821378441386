import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

class CampaignListing extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      search: "",
      sort: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      categoryData: [],
      category: "",
      activeTab: "All",
      isVisible: false,
      successMsg: "",
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.getAllCategories();
    this.getCampaignResults();
  }

  getAllCategories = () => {
    const { token } = this.state;
    const { AdminServicesList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminServicesList}`;
    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, categories } = response.data;

        if (status === 200) {
          this.setState({
            categoryData: categories,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getCampaignResults = () => {
    const {
      search,
      token,
      currentPage,
      itemsPerPage,
      category,
      sort,
      activeTab,
    } = this.state;
    const { AdminCampaignList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    if (activeTab !== "All") {
      queryParams += `&status=${activeTab.toLowerCase()}`;
    }

    const url = `${AdminCampaignList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, campaigns, totalItems } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: campaigns,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getCampaignResults();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getCampaignResults();
    });
  };

  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  handleCloseContent = () => {
    this.setState({ isVisible: false });
  };

  handleSearchInputChange = (e) => {
    console.log("Selected Category:", e.target.value);

    this.setState(
      {
        category: e.target.value,
        currentPage: 1, // Reset currentPage when the category changes
      },
      this.getCampaignResults
    );
  };

  handleToggleCheckBox = async (campaignId, currentStatus) => {
    const { token } = this.state;
    const { AdminCampaignStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      campaignId,
      status: newStatus,
    };

    try {
      const response = await APICaller(
        AdminCampaignStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((campaign) =>
            campaign._id === campaignId
              ? { ...campaign, status: newStatus }
              : campaign
          ),
          successMsg: `Campaign Status is ${newStatus}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
      } else {
        console.error("Failed to update offer status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      categoryData,
      category,
      sort,
      activeTab,
      successMsg,
      errorMsg,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((item) => {
            const matchesSearch = item.title
              .toLowerCase()
              .includes(search.toLowerCase());
            const matchesCategory =
              category === "" ||
              item.service_name.some((c) => c.service_id === category);

            return matchesSearch && matchesCategory;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a.title.localeCompare(b.title)
              : b.title.localeCompare(a.title);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Campaign List</li>
                    </ol>
                    <h4 className="card-title mb-0">Campaign List</h4>
                  </div>
                  <div className="ms-auto">
                    <Link to="/AddCampaign" className="btn-blue ms-2">
                      <i className="fa-solid fa-plus me-2"></i>Genrate Campaign
                    </Link>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Active")}
                    className={activeTab === "Active" ? "active" : ""}
                  >
                    Active
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Inactive")}
                    className={activeTab === "Inactive" ? "active" : ""}
                  >
                    Inactive
                  </Link>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-lg-4">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="">
                        <select
                          className="form-select"
                          name="category"
                          value={category}
                          onChange={this.handleSearchInputChange}
                          onBlur={this.getCampaignResults}
                        >
                          <option value="">All Service</option>
                          {categoryData.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.service_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) =>
                            this.setState({ sort: e.target.value })
                          }
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Campaign</th>
                      <th>User Name</th>
                      <th>Service</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Created At</th>
                      <th>Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No campaigns available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((item, index) => {
                        const uniqueUserNames = new Set();
                        const uniqueServices = new Set();
                        const uniqueSubServices = new Set();
                        const uniquePrices = new Set();

                        item.campaignAssignments.forEach((assignment) => {
                          if (assignment.user && assignment.user.name) {
                            uniqueUserNames.add(assignment.user.name);
                          }

                          assignment.services.forEach((service) => {
                            uniqueServices.add(service.service_name);
                          });

                          assignment.sub_services.forEach((subService) => {
                            uniqueSubServices.add(
                              subService.subService.sub_service_name
                            );
                            uniquePrices.add(subService.price);
                          });
                        });

                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>{item.title}</td>
                            <td>{Array.from(uniqueUserNames).join(", ")}</td>
                            <td>
                              {Array.from(uniqueServices).map(
                                (service, index) => (
                                  <div key={index}>
                                    {service} -{" "}
                                    {Array.from(uniqueSubServices).map(
                                      (subService, index) => (
                                        <span key={index}>
                                          {subService} (Price:{" "}
                                          {Array.from(uniquePrices).join(", ")})
                                          {index < uniqueSubServices.size - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                )
                              )}
                            </td>
                            <td>{formatDate(item.startDate)}</td>
                            <td>{formatDate(item.endDate)}</td>
                            <td>{formatDate(item.createdAt)}</td>
                            <td className="text-center">
                              {item.status && (
                                <div className="form-check form-switch d-inline-block p-0 m-0">
                                  <input
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    checked={item.status === "Active"}
                                    onChange={() =>
                                      this.handleToggleCheckBox(
                                        item._id,
                                        item.status
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <Link to="#" className="icon-btn">
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                              <Link
                                to="#"
                                className="icon-btn"
                                onClick={this.toggleVisibility}
                              >
                                <img
                                  src="assets/img/icon-view.svg"
                                  alt=""
                                  title=""
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
              ;
            </div>
          </div>
        </div>

        <div
          className={`right-side-popup ${
            this.state.isVisible ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={this.handleCloseContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">#857687</h4>
                  <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                </div>
                <div className="ms-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="">
                      <select className="form-select">
                        <option selected="">Pending</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="ms-3">
                      <button type="button" className="btn-blue red-btn-color">
                        <i className="fa-regular fa-trash-can me-2"></i>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="mb-3 tc-8">Offer Information</h5>
              <h5 className="mb-2 tc-6">Buy 1 get 2 free</h5>
              <p className="mb-2 tc-5">
                Purchase Limit: <span className="tc-6">10</span>
              </p>
              <p className="mb-2 tc-5">
                Start Date: <span className="tc-6">23 Sep, 2023</span>
              </p>
              <p className="mb-4 tc-5">
                End Date: <span className="tc-6">30 Sep, 2023</span>
              </p>

              <h5 className="mb-2 tc-8">Selected Services</h5>

              <div className="d-flex mb-3">
                <div className="btn-tag me-2 mb-2">Fades</div>
                <div className="btn-tag me-2 mb-2">Tapers</div>
                <div className="btn-tag me-2 mb-2">Beard Trims</div>
                <div className="btn-tag me-2 mb-2">Hair Coloring</div>
              </div>

              <h4 className="mb-3">Stylist Detail</h4>
              <div className="row align-items-center mb-3">
                <div className="col-lg-8">
                  <div className="d-flex align-items-center">
                    <figure className="flex-shrink-0 me-3 mb-0 user-img-40">
                      <img src="assets/img/dummy-user.png" alt="" title="" />
                    </figure>
                    <div className="">
                      <p className="mb-0">
                        <strong>James Bond</strong>
                      </p>
                      <p className="fs-12 mb-0">@jamesbond656</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-end">
                  <button type="button" className="btn-light-round">
                    <i className="fa-regular fa-message"></i>
                  </button>
                </div>
                <div className="col-lg-12">
                  <p className="h5 fw-normal mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua...
                    <a href="#" className="tc-6 fw-bold">
                      read more
                    </a>
                  </p>
                </div>
              </div>

              <h4 className="fw-medium">Price Details</h4>

              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Hair Cut</span>
                <span className="tc-6 fw-medium ms-auto">$20</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Bread Trim</span>
                <span className="tc-6 fw-medium ms-auto">$10</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Discount</span>
                <span className="tc-6 fw-medium ms-auto">10%</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Services</span>
                <span className="tc-6 fw-medium ms-auto">3</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Start Date</span>
                <span className="tc-6 fw-medium ms-auto">23 Sep, 2023</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">End Date</span>
                <span className="tc-6 fw-medium ms-auto">30 Sep, 2023</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Payment Method</span>
                <span className="tc-6 fw-medium ms-auto">MasterCard</span>
              </p>
              <hr className="my-3" />
              <p className="h5 d-flex align-items-center mb-2 tc-6">
                <span className="fw-medium">Total (USD)</span>
                <span className="tc-6 fw-medium ms-auto">$144.90</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(CampaignListing);
