import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import Logout from "../components/Logout";
import Register from "../components/Register";
import Home from "../components/Home";

import ManageHomeBanners from "../components/ManageBanner";
import EditBanner from "../components/ManageBanner/editBanner";

import ManageServices from "../components/ManageServices";
import AddServices from "../components/ManageServices/addServices";
import EditServices from "../components/ManageServices/editServices";
import ViewServices from "../components/ManageServices/viewServices";

import AddSubServices from "../components/ManageServices/addSubServices";
import EditSubServices from "../components/ManageServices/editSubServices";

import ManageCategory from "../components/ManageCategory";
import AddCategory from "../components/ManageCategory/addCategory";
import EditCategory from "../components/ManageCategory/editCategory";
import ViewCategory from "../components/ManageCategory/viewCategory";

import ManageState from "../components/State";
import AddState from "../components/State/addState";
import EditState from "../components/State/editState";
import ViewState from "../components/State/viewState";

import ManageDistrict from "../components/District";
import AddDistrict from "../components/District/addDistrict";
import EditDistrict from "../components/District/editDistrict";
import ViewDistrict from "../components/District/viewDistrict";

import ManageCity from "../components/City";
import AddCity from "../components/City/addCity";
import EditCity from "../components/City/editCity";
import ViewCity from "../components/City/viewCity";

import ManageExperts from "../components/ExpertList";
import AddExpert from "../components/ExpertList/addExpert";
import EditExpert from "../components/ExpertList/editExpert";
import ViewExpert from "../components/ExpertList/viewExpert";

import AssignServices from "../components/ExpertList/AssignServices";
import UploadUserImage from "../components/ExpertList/ExpertGallary";

import AmenitiesAndOthers from "../components/ExpertList/AmenitiesAndOthers";
import ShopInformation from "../components/ExpertList/ShopInformation";

import ManageFaqs from "../components/ManageFaqs";
import AddFaq from "../components/ManageFaqs/addFaq";
import EditFaq from "../components/ManageFaqs/editFaq";
import ViewFaq from "../components/ManageFaqs/viewFaq";

import CMS from "../components/CMS";
import AddCMSPage from "../components/CMS/addCMSPage";
import EditCMSPage from "../components/CMS/editCMSPage";
import ViewCMSPage from "../components/CMS/viewCMSPage";

import Campaign from "../components/Campaign";
import AddCampaign from "../components/Campaign/addCampaign";
import EditCampaign from "../components/Campaign/editCampaign";
import ViewCampaign from "../components/Campaign/viewCampaign";

import ManageAppointments from "../components/Appointments";
import ViewAppointments from "../components/Appointments/viewAppointments";

import ManageOffers from "../components/Offers";
import AddOffers from "../components/Offers/addOffers";
import EditOffers from "../components/Offers/editOffers";
import ViewOffers from "../components/Offers/viewOffers";

import ManagePackages from "../components/Packages";
import ViewPackages from "../components/Packages/viewPackages";

import ManageUserJobs from "../components/UserJobs";
import ViewUserJobs from "../components/UserJobs/viewUserJobs";

import ExpertInbox from "../components/ExpertInbox";

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/ManageExperts" element={<ManageExperts />} />
        <Route path="/AddExpert" element={<AddExpert />} />
        <Route path="/EditExpert/:id" element={<EditExpert />} />
        <Route path="/ViewExpert/:id" element={<ViewExpert />} />
        <Route path="/AssignServices/:id" element={<AssignServices />} />
        {/* <Route path="/ViewExpert/:id" element={<ViewExpert />} />
        <Route path="/UploadUserImage/:id" element={<UploadUserImage />} /> */}
        <Route path="/UploadUserImage/:id" element={<UploadUserImage />} />

        <Route
          path="/AmenitiesAndOthers/:id"
          element={<AmenitiesAndOthers />}
        />

        <Route path="/ShopInformation/:id" element={<ShopInformation />} />

        <Route path="/ManageCategory" element={<ManageCategory />} />
        <Route path="/AddCategory" element={<AddCategory />} />
        <Route path="/EditCategory/:id" element={<EditCategory />} />
        <Route path="/ViewCategory/:id" element={<ViewCategory />} />

        <Route path="/ManageServices" element={<ManageServices />} />
        <Route path="/AddServices" element={<AddServices />} />
        <Route path="/EditServices/:id" element={<EditServices />} />
        <Route path="/ViewServices/:id" element={<ViewServices />} />

        <Route path="/AddSubServices/:id" element={<AddSubServices />} />

        <Route path="/EditSubServices/:id" element={<EditSubServices />} />

        <Route path="/ManageHomeBanners" element={<ManageHomeBanners />} />
        <Route path="/EditBanner/:id" element={<EditBanner />} />

        <Route path="/ManageState" element={<ManageState />} />
        <Route path="/AddState" element={<AddState />} />
        <Route path="/EditState/:id" element={<EditState />} />
        <Route path="/ViewState/:id" element={<ViewState />} />
        <Route path="/ManageDistrict" element={<ManageDistrict />} />
        <Route path="/AddDistrict" element={<AddDistrict />} />
        <Route path="/EditDistrict/:id" element={<EditDistrict />} />
        <Route path="/ViewDistrict/:id" element={<ViewDistrict />} />
        <Route path="/ManageCity" element={<ManageCity />} />
        <Route path="/AddCity" element={<AddCity />} />
        <Route path="/EditCity/:id" element={<EditCity />} />
        <Route path="/ViewCity/:id" element={<ViewCity />} />
        <Route path="/ManageFaqs" element={<ManageFaqs />} />
        <Route path="/AddFaq" element={<AddFaq />} />
        <Route path="/EditFaq/:id" element={<EditFaq />} />
        <Route path="/ViewFaq/:id" element={<ViewFaq />} />
        <Route path="/CMS" element={<CMS />} />
        <Route path="/AddCMSPage" element={<AddCMSPage />} />
        <Route path="/EditCMSPage/:id" element={<EditCMSPage />} />
        <Route path="/ViewCMSPage/:id" element={<ViewCMSPage />} />
        <Route path="/ManageCampaigns" element={<Campaign />} />
        <Route path="/AddCampaign" element={<AddCampaign />} />
        <Route path="/EditCampaign/:id" element={<EditCampaign />} />
        <Route path="/ViewCampaign/:id" element={<ViewCampaign />} />
        <Route path="/ManageAppointments" element={<ManageAppointments />} />
        <Route path="/viewAppointments/:id" element={<ViewAppointments />} />
        <Route path="/ManagePackages" element={<ManagePackages />} />
        <Route path="/ViewPackages/:id" element={<ViewPackages />} />
        <Route path="/ManageOffers" element={<ManageOffers />} />
        <Route path="/AddOffers" element={<AddOffers />} />

        <Route path="/EditOffers/:id" element={<EditOffers />} />
        <Route path="/ViewOffers/:id" element={<ViewOffers />} />
        <Route path="/ExpertInbox" element={<ExpertInbox />} />
        <Route path="/ManageUserJobs" element={<ManageUserJobs />} />
        <Route path="/viewUserJobs/:id" element={<viewUserJobs />} />

        <Route path="/Logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigator;
