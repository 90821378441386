import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

export default class ExpertInbox extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      users: [],
      selectedUserId: null,
      messages: [],
    };
  }

  componentDidMount() {
    const userId = window.location.pathname.split("/").pop();
    this.getUserList("64d25dd14a62e3dbdddc6f9c");
  }

  getUserList = async (userId) => {
    const { token } = this.state;
    const { AdminGetChatParticipants } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { userId: userId };
      const response = await APICaller(
        AdminGetChatParticipants,
        method,
        body,
        contentType,
        token
      );

      const { status, chatParticipants } = response.data;

      if (status === 200) {
        this.setState({
          users: chatParticipants,
        });
      } else {
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching users by category. Please try again.",
        successMsg: "",
      });
    }
  };

  handleUserClick = async (chatId) => {
    const { token } = this.state;
    const { AdminGetMessageListByChatId } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { chatId: chatId };
      const response = await APICaller(
        AdminGetMessageListByChatId,
        method,
        body,
        contentType,
        token
      );

      const { status, messages } = response.data;

      if (status === 200) {
        this.setState({
          messages: messages,
        });
      } else {
        // Handle error
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching chat messages. Please try again.",
        successMsg: "",
      });
    }
  };

  render() {
    const { users, token, messages } = this.state;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <h4 className="card-title mb-0">
                    Expert - User (Supervision)
                  </h4>
                </div>
                <div className="col-lg-8">
                  <div className="d-flex align-items-center justify-content-end">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-calendar.svg"
                        alt=""
                        title=""
                      />
                      January
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="chat mb-4">
            <div className="row">
              <div className="col-lg-9">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="chat-msg-header">
                          <div className="form-control-group-icon search mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                            />
                            <i className="fa-solid fa-magnifying-glass"></i>
                          </div>
                          <div class="chat-tabs d-flex align-items-center mb-4">
                            <a href="#" className="me-2 active">
                              All
                            </a>
                            <a href="#" className="me-2">
                              Unread
                            </a>
                          </div>
                        </div>

                        <div className="chat-msg-body">
                          {users.length === 0 ? (
                            <div>No records found.</div>
                          ) : (
                            users.map((chat) => (
                              <div key={chat.chatId} className="chat-msg-item">
                                {chat.users.map((user) => (
                                  <div
                                    key={user.userId}
                                    className="d-flex align-items-center justify-content-between mb-3"
                                    onClick={() =>
                                      this.handleUserClick(chat.chatId)
                                    }
                                  >
                                    <div className="me-3">
                                      <div className="d-flex">
                                        <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                          <img
                                            src="assets/img/dummy-user.png"
                                            alt=""
                                            title=""
                                          />
                                        </figure>
                                        <div className="flex-grow-1">
                                          <p className="mb-1 tc-6">
                                            <strong>{user.username}</strong>
                                          </p>
                                          {chat.lastMessage ? (
                                            <p className="fs-12 tc-5 mb-0">
                                              {chat.lastMessage.content}
                                            </p>
                                          ) : (
                                            <p className="fs-12 tc-5 mb-0">
                                              No messages
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      {chat.lastMessage ? (
                                        <p className="fs-12 mb-0 tc-5">
                                          {formatDate(
                                            chat.lastMessage.timestamp
                                          )}
                                        </p>
                                      ) : (
                                        <p className="fs-12 mb-0 tc-5"></p>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))
                          )}
                        </div>
                      </div>

                      <div className="col-lg-7">
                        <div className="chat-area">
                          <div className="chat-area-header">
                            <div class="d-flex align-items-cenchat-area-bodyter justify-content-between mb-3">
                              <div class="me-3">
                                <div class="d-flex align-items-center">
                                  <figure class="flex-shrink-0 me-2 mb-0 user-img-40">
                                    <img
                                      src="assets/img/dummy-user.png"
                                      alt=""
                                      title=""
                                    />
                                  </figure>
                                  <div class="flex-grow-1">
                                    <p class="mb-0">
                                      <strong class="tc-6">
                                        Linda michzaosky
                                      </strong>
                                    </p>
                                    <p class="fs-13 tc-7 mb-0">
                                      #3987544 • Aug 23, 2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="chat-area-body">
                            {messages.length === 0 ? (
                              <div>No messages found.</div>
                            ) : (
                              messages.map((message) => (
                                <div
                                  key={message._id}
                                  className={`chat-item ${
                                    message.sender._id ===
                                    "64d25dd14a62e3dbdddc6f9c"
                                      ? ""
                                      : "chat-item-right-side ms-auto"
                                  }`}
                                >
                                  <div class="d-flex">
                                    <figure
                                      class={`flex-shrink-0 me-2 mb-0 user-img-35 chat-item-user ${
                                        message.sender._id ===
                                        "64d25dd14a62e3dbdddc6f9c"
                                          ? "offline"
                                          : "online"
                                      }`}
                                    >
                                      <img
                                        src="assets/img/dummy-user.png"
                                        alt=""
                                        title=""
                                      />
                                    </figure>
                                    <div class="flex-grow-1 chat-item-chat">
                                      <div class="d-flex align-items-end">
                                        <div class="flex-grow-1 me-3">
                                          <p class="fs-13 tc-6 mb-0">
                                            {message.content}
                                          </p>
                                        </div>
                                        <div class="flex-shrink-0">
                                          <p class="fs-12 tc-5 mb-0">
                                            {formatDate(message.timestamp)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
