// ViewCategory.js
import React, { Component } from "react";

class ViewOffers extends Component {
  render() {
    const {
      isVisibleView,
      handleCloseViewContent,
      offerDetailsresults,
      formatDate,
    } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup ${
            isVisibleView ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseViewContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">#857687</h4>
                  <p className="mb-0 tc-5">
                    Last Updated: {formatDate(offerDetailsresults.createdAt)}
                  </p>
                </div>
                <div className="ms-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="">
                      <select className="form-select">
                        <option selected="">Pending</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="ms-3">
                      <button type="button" className="btn-blue red-btn-color">
                        <i className="fa-regular fa-trash-can me-2"></i>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="mb-3 tc-8">Offer Information</h5>
              <h5 className="mb-2 tc-6">{offerDetailsresults.offer_name}</h5>
              <p className="mb-2 tc-5">
                Number of offers:
                <span className="tc-6">
                  {offerDetailsresults.number_of_offers}
                </span>
              </p>
              <p className="mb-2 tc-5">
                Start Date:{" "}
                <span className="tc-6">
                  {formatDate(offerDetailsresults.start_date)}
                </span>
              </p>
              <p className="mb-4 tc-5">
                End Date:
                <span className="tc-6">
                  {" "}
                  {formatDate(offerDetailsresults.end_date)}
                </span>
              </p>

              <h5 className="mb-2 tc-8">Selected Services</h5>

              <div>
                <div className="d-flex mb-3">
                  {offerDetailsresults.service_name &&
                  Array.isArray(offerDetailsresults.service_name) ? (
                    offerDetailsresults.service_name.map((service, index) => (
                      <div className="btn-tag me-2 mb-2" key={index}>
                        {service.service_name}
                      </div>
                    ))
                  ) : (
                    <div>No services available</div>
                  )}
                </div>
              </div>

              <h4 className="mb-3">Stylist Detail</h4>
              <div className="row align-items-center mb-3">
                <div className="col-lg-8">
                  <div className="d-flex align-items-center">
                    <figure className="flex-shrink-0 me-3 mb-0 user-img-40">
                      <img src="assets/img/dummy-user.png" alt="" title="" />
                    </figure>
                    <div className="">
                      <p className="mb-0">
                        <strong>
                          {offerDetailsresults.expertDetails
                            ? offerDetailsresults.expertDetails.name
                            : null}
                        </strong>
                      </p>
                      <p className="fs-12 mb-0">
                        {offerDetailsresults.expertDetails
                          ? offerDetailsresults.expertDetails.username
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-end">
                  <button type="button" className="btn-light-round">
                    <i className="fa-regular fa-message"></i>
                  </button>
                </div>
                <div className="col-lg-12">
                  <p className="h5 fw-normal mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua...
                    <a href="#" className="tc-6 fw-bold">
                      read more
                    </a>
                  </p>
                </div>
              </div>

              <h4 className="fw-medium">Price Details</h4>

              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Hair Cut</span>
                <span className="tc-6 fw-medium ms-auto">$20</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Bread Trim</span>
                <span className="tc-6 fw-medium ms-auto">$10</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Discount</span>
                <span className="tc-6 fw-medium ms-auto">10%</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Services</span>
                <span className="tc-6 fw-medium ms-auto">3</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Start Date</span>
                <span className="tc-6 fw-medium ms-auto">23 Sep, 2023</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">End Date</span>
                <span className="tc-6 fw-medium ms-auto">30 Sep, 2023</span>
              </p>
              <p className="h5 d-flex align-items-center mb-2 tc-5">
                <span className="fw-normal">Payment Method</span>
                <span className="tc-6 fw-medium ms-auto">MasterCard</span>
              </p>
              <hr className="my-3" />
              <p className="h5 d-flex align-items-center mb-2 tc-6">
                <span className="fw-medium">Total (USD)</span>
                <span className="tc-6 fw-medium ms-auto">$144.90</span>
              </p>
            </div>
          </div>
        </div>

        <div
          className={`right-side-popup-backdrop ${
            isVisibleView ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

export default ViewOffers;
