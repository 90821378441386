import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class District extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      stateResults: [],
      emailErrorMsg: "",
      searchState: "",
      searchDistrict: "",
      searchStatus: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.getDistrictResults();
    this.getStateResults();
  }

  getStateName = (stateId) => {
    const { stateResults } = this.state;
    const state = stateResults.find((item) => item._id === stateId);
    return state ? state.state_name : "N/A"; // Replace "N/A" with a default value or message if state name is not found
  };

  getStateResults = () => {
    const { token } = this.state;

    const { AdminStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminStateName}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            stateResults: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getDistrictResults = () => {
    const {
      searchDistrict,
      searchStatus,
      token,
      currentPage,
      itemsPerPage,
      searchState,
    } = this.state;

    const { AdminDistrictList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    // Assuming the state_id for "Patiala" is a valid ObjectId
    if (searchState) {
      queryParams += `&state_id=${searchState}`; // e.g., "state_id=615c61c09a9bde2d74936328"
    }

    if (searchDistrict) {
      queryParams += `&district_name=${searchDistrict}`;
    }
    if (searchStatus) {
      queryParams += queryParams
        ? `&status=${searchStatus}`
        : `status=${searchStatus}`;
    }

    const url = `${AdminDistrictList}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, districts, totalItems } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: districts,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.setState({ currentPage: 1 }, () => {
      this.getDistrictResults();
    });
  };

  handleSearchInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "searchState") {
      const selectedStateId = e.target.options[e.target.selectedIndex].value;
      this.setState({ [name]: selectedStateId });
    } else {
      this.setState({ [name]: value });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getDistrictResults();
    });
  };

  handleReset = () => {
    this.setState(
      {
        searchDistrict: "",
        searchStatus: "",
      },
      () => {
        this.getDistrictResults();
      }
    );
  };

  render() {
    const {
      results,
      token,
      searchDistrict,
      searchStatus,
      searchState,
      currentPage,
      itemsPerPage,
      totalItems,
      stateResults,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />

        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Search District</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">District</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header card-header-tool-align">
                  <h3 className="card-title">Search District</h3>
                  <div className="card-tools">
                    <Link to="/AddDistrict" className="btn btn-sm btn-success">
                      Add District
                    </Link>
                  </div>
                </div>

                <form
                  onSubmit={this.handleSearchSubmit}
                  onReset={this.handleReset}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="status">Select State</label>
                          <select
                            name="searchState"
                            value={searchState}
                            className="form-control"
                            onChange={this.handleSearchInputChange}
                            id="status"
                          >
                            <option value="">Select State</option>
                            {stateResults.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.state_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="district_name">District Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="district_name"
                            placeholder="District Name"
                            name="searchDistrict"
                            value={searchDistrict}
                            onChange={this.handleSearchInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <select
                            name="searchStatus"
                            value={searchStatus}
                            className="form-control"
                            onChange={this.handleSearchInputChange}
                            id="status"
                          >
                            <option value="">Select Status</option>
                            <option value="Active">Active</option>
                            <option value="In-Active">In-Active</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary mr-2">
                      Search
                    </button>
                    <button type="reset" className="btn btn-secondary">
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">District List</h3>
                </div>

                <div className="card-body p-0">
                  <table className="table table-striped projects">
                    <thead>
                      <tr>
                        <th>State Name</th>
                        <th>District Name</th>
                        <th>Created Date</th>
                        <th className="text-center">Status</th>
                        <th className="text-center" style={{ width: "170px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No records found.
                          </td>
                        </tr>
                      ) : (
                        results.map((item) => (
                          <tr key={item.id}>
                            <td>{this.getStateName(item.state_id)}</td>
                            <td>{item.district_name}</td>
                            <td>{item.createdAt}</td>
                            <td className="project-state">
                              {item.status === "Active" ? (
                                <span className="badge badge-success">
                                  {item.status}
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  {item.status}
                                </span>
                              )}
                            </td>

                            <td className="project-actions text-center">
                              <Link
                                to={`/ViewDistrict/${item._id}`}
                                title="View"
                                className="btn btn-primary btn-sm m-1"
                              >
                                <i className="fas fa-folder"></i>
                              </Link>
                              <Link
                                to={`/EditDistrict/${item._id}`}
                                title="Edit"
                                className="btn btn-info btn-sm m-1"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-wrapper">
              <ul className="pagination">
                {Array.from({
                  length: Math.ceil(totalItems / itemsPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => this.handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(District);
