import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditBanner extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      loading: false,
      banner_name: "",
      startDate: "",
      endDate: "",
      position: "",
      description: "",
      status: "",
      fileName: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      selectedFile: null,
      previewURL: null,
      results: "",
      id: "",
      banner_type: "",
    };
  }

  componentDidMount() {
    const svcid = window.location.pathname.split("/").pop();

    console.log(svcid, "svcid");

    this.getBannerDetails(svcid);
  }

  getBannerDetails = (id) => {
    const { token } = this.state;
    const { AdminBannerDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminBannerDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, banner } = response.data;

        if (status === 200) {
          this.setState({
            results: banner,
            banner_name: banner.banner_name,
            id: banner._id,
            status: banner.status,
            position: banner.position,
            startDate: banner.startDate,
            endDate: banner.endDate,
            description: banner.description,
            banner_type: banner.banner_type,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { banner_name, position, banner_type } = this.state;
    const newErrors = {};

    if (!banner_name) {
      newErrors.banner_name = "Banner Name is required.";
    }

    if (!position) {
      newErrors.position = "Position is required.";
    }

    if (!banner_type) {
      newErrors.banner_type = "Banner type is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const {
        banner_name,
        selectedFile,
        token,
        description,
        startDate,
        endDate,
        position,
        id,
        status,
        banner_type,
      } = this.state;

      const { AdminEditBanner } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("banner_name", banner_name);
      formData.append("fileName", selectedFile);
      formData.append("description", description);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("position", position);
      formData.append("status", status);
      formData.append("banner_type", banner_type);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminEditBanner,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Banner Name updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This Banner Name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create Banner Name. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the Banner Name. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "", // Clear the fileName state
    });
  };

  handlePositionChange = (e) => {
    this.setState({ position: e.target.value });
  };

  handleBannerTypeChange = (e) => {
    this.setState({ banner_type: e.target.value });
  };

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value });
  };

  render() {
    const {
      banner_name,
      startDate,
      endDate,
      position,
      description,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      previewURL,
      status,
      loading,
      banner_type,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageHomeBanners" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Banner Information</h4>
                  <p className="mb-0 tc-5">Edit Banner</p>
                </div>
                <div className="ms-auto"> </div>
              </div>

              <form
                className="form-area row align-items-center mb-5"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                {errorMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errorMsg}
                  </div>
                )}

                {successMsg && (
                  <div className="alert alert-success" role="alert">
                    {successMsg}
                  </div>
                )}

                {loading && (
                  <div className="d-flex justify-content-center align-items-center loader">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <div className="form-area1 row g-4">
                  <div className="col">
                    <div className="upload-img-box">
                      <i className="fa-solid fa-file-arrow-up"></i>
                      <p className="mb-0">Upload Image</p>
                      <input
                        type="file"
                        className="form-control"
                        id="fileName"
                        placeholder="Absence through"
                        name="fileName"
                        accept="image/*"
                        onChange={this.handleFileSelect}
                      />
                      {previewURL && (
                        <div>
                          <img
                            src={previewURL}
                            alt="Category Preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                          <button type="button" onClick={this.handleFileRemove}>
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <label htmlFor="banner_name" className="form-label">
                      Banner Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="banner_name"
                      placeholder="Banner Name"
                      name="banner_name"
                      value={banner_name}
                      onChange={this.handleInputChange}
                    />
                    {errors && (
                      <div className="callout callout-danger">
                        {errors.banner_name && (
                          <p className="error">{errors.banner_name}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      aria-label="Default select example"
                      value={status}
                      onChange={this.handleStatusChange}
                    >
                      <option value="">Select Position</option>
                      <option value="Active">Active</option>
                      <option value="In-Active">In-Active</option>
                    </select>
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="Position" className="form-label">
                      Position
                    </label>
                    <select
                      className="form-select"
                      id="position"
                      aria-label="Default select example"
                      value={position}
                      onChange={this.handlePositionChange}
                    >
                      <option value="">Select Position</option>
                      <option value="Top">Top</option>
                      <option value="Middle">Middle</option>
                      <option value="Bottom">Bottom</option>
                    </select>
                    {errors.position && (
                      <div className="callout callout-danger">
                        <p className="error">{errors.position}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="banner_type" className="form-label">
                      Banner Type
                    </label>
                    <select
                      className="form-select"
                      id="banner_type"
                      aria-label="Default select example"
                      value={banner_type}
                      onChange={this.handleBannerTypeChange}
                    >
                      <option value="">Select Banner Type</option>
                      <option value="Home">Home</option>
                      <option value="Offer">Offer</option>
                      <option value="Package">Package</option>
                      <option value="Appointments">Appointments</option>
                    </select>
                    {errors.position && (
                      <div className="callout callout-danger">
                        <p className="error">{errors.banner_type}</p>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="startDate" className="form-label">
                      Start Date
                    </label>
                    <input
                      type="Date"
                      className="form-control"
                      id="startDate"
                      placeholder="Start Name"
                      name="startDate"
                      value={startDate}
                      onChange={this.handleInputChange}
                    />
                    {errors && (
                      <div className="callout callout-danger">
                        {errors.startDate && (
                          <p className="error">{errors.startDate}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="endDate" className="form-label">
                      End Date
                    </label>
                    <input
                      type="Date"
                      className="form-control"
                      id="endDate"
                      placeholder="End Name"
                      name="endDate"
                      value={endDate}
                      onChange={this.handleInputChange}
                    />
                    {errors && (
                      <div className="callout callout-danger">
                        {errors.endDate && (
                          <p className="error">{errors.endDate}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      placeholder="Banner Description"
                      name="description"
                      value={description}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </div>
                  <div className="col-lg-12">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditBanner);
