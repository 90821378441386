import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditState extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      country_id: "",
      state_name: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      id: "",
    };
  }

  componentDidMount() {
    const stateid = window.location.pathname.split("/").pop();
    this.getStateResults(stateid);
  }

  getStateResults = (id) => {
    const { token } = this.state;
    const { AdminStateDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminStateDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data,
            state_name: data.state_name,
            country_id: data.country_id,
            id: data._id,
            status: data.status,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { country_id, state_name } = this.state;
    const newErrors = {};

    if (!country_id) {
      newErrors.country_id = "Country name is required.";
    }

    if (!state_name) {
      newErrors.state_name = "State name is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { country_id, state_name, token, status, id } = this.state;

      const { AdminEditState } = adminendPoints;
      const method = "POST";
      const body = {
        country_id,
        state_name,
        status,
        id,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminEditState,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "State updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This State name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update State. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the State. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      country_id,
      state_name,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      status,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageState" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Edit State</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>

                    <li className="breadcrumb-item active">Edit State </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header card-header-tool-align">
                    <h3 className="card-title">Edit State </h3>

                    <div className="card-tools">
                      <Link
                        to="/ManageState"
                        className="btn btn-sm btn-success"
                      >
                        State Listing
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      {errorMsg && (
                        <div className="alert alert-danger alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          <h5>
                            <i className="icon fas fa-ban"></i> Alert!
                          </h5>
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                          <h5>
                            <i className="icon fas fa-check"></i> Alert!
                          </h5>
                          {successMsg}
                        </div>
                      )}

                      {errors && (
                        <div className="callout callout-danger">
                          <h5>All * fields are required</h5>
                          {errors.state_name && (
                            <p className="error">{errors.state_name}</p>
                          )}
                          {errors.country_id && (
                            <p className="error">{errors.country_id}</p>
                          )}
                          {errors.status && (
                            <p className="error">{errors.status}</p>
                          )}
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="country_id">Country Name</label>
                            <select
                              name="country_id"
                              className="custom-select rounded-0"
                              id="country_id"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Country Name</option>
                              <option
                                selected={country_id === "India"}
                                value="India"
                              >
                                India
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="state_name">State Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="state_name"
                              placeholder="State Name"
                              name="state_name"
                              value={state_name}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                              name="status"
                              className="custom-select rounded-0"
                              id="status"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Status</option>
                              <option
                                value="Active"
                                selected={status === "Active"}
                              >
                                Active
                              </option>
                              <option
                                value="In-Active"
                                selected={status === "In-Active"}
                              >
                                In-Active
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(EditState);
