import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditCity extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      city_name: "",
      district_id: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      districtResults: [],
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      id: "",
      status: "",
    };
  }

  componentDidMount() {
    const cityid = window.location.pathname.split("/").pop();
    this.getCityResults(cityid);
    this.getDistrictResults();
  }

  getDistrictResults = () => {
    const { token } = this.state;

    const { AdminDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminDistrictName}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            districtResults: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getCityResults = (id) => {
    const { token } = this.state;

    const { AdminCityDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminCityDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          this.setState({
            results: data,
            district_id: data.district_id,
            city_name: data.city_name,
            id: data._id,
            status: data.status,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { district_id, city_name } = this.state;
    const newErrors = {};

    if (!district_id) {
      newErrors.district_id = "District name is required.";
    }

    if (!city_name) {
      newErrors.city_name = "City name is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { city_name, district_id, token, status, id } = this.state;

      const { AdminEditCity } = adminendPoints;
      const method = "POST";
      const body = {
        id,
        district_id,
        city_name,
        status,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminEditCity,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "City updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This City name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create City. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      districtResults,
      district_id,
      city_name,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      status,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageCity" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Manage City</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>

                    <li className="breadcrumb-item active">City </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">City </h3>

                    <div className="card-tools">
                      <Link to="/ManageCity" className="btn btn-sm btn-success">
                        Manage City
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      {errorMsg && (
                        <div className="alert alert-danger alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          <h5>
                            <i className="icon fas fa-ban"></i> Alert!
                          </h5>
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                          <h5>
                            <i className="icon fas fa-check"></i> Alert!
                          </h5>
                          {successMsg}
                        </div>
                      )}

                      {errors && (
                        <div className="callout callout-danger">
                          <h5>All * fields are required</h5>
                          {errors.city_name && (
                            <p className="error">{errors.city_name}</p>
                          )}
                          {errors.district_id && (
                            <p className="error">{errors.district_id}</p>
                          )}
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="state_id">District Name</label>
                            <select
                              name="district_id"
                              value={district_id}
                              className="custom-select rounded-0"
                              onChange={this.handleInputChange} // Change this to handleInputChange
                              id="district_id"
                            >
                              <option value="">Select District Name</option>
                              {districtResults.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.district_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="city_name">City Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city_name"
                              placeholder="City Name"
                              name="city_name"
                              value={city_name}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                              name="status"
                              className="custom-select rounded-0"
                              id="status"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Status</option>
                              <option
                                value="Active"
                                selected={status === "Active"}
                              >
                                Active
                              </option>
                              <option
                                value="In-Active"
                                selected={status === "In-Active"}
                              >
                                In-Active
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(EditCity);
