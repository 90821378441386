import React, { Component } from "react";
import Select from "react-select";
import APICaller from "../../utils/apicaller";
import { adminendPoints } from "../../config";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { formatDate } from "../../utils/commonFunction";
import moment from "moment";
import { Link, Navigate } from "react-router-dom";

class LargeForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      states: [],
      districts: [],
      cities: [],
      reviewsResults: [],
      predefinedState: null,
      predefinedDistrict: null,
      predefinedCity: null,
      experience: "",
      homeVisit: "",
      freelancer: "",
      username: "",
      name: "",
      email: "",
      phone: "",
      password: "",
      createdAt: "",
      updatedAt: "",
      password: "",
      working_hours: "",
      pincode: "",
      address: "",
      srviceForMenWomen: "",
      aboutMe: "",
      experience: "",
      course: "",
      course_name: "",
      facebook_url: "",
      instagram_url: "",
      gstNumber: "",
      bankAccountNumber: "",
      aadharNumber: "",
      amenities: {
        music: "No",
        parking_space: "No",
        credit_cards_accepted: "No",
        wi_fi: "No",
        pets_friendly: "No",
        child_friendly: "No",
        selfie_station: "No",
      },
      aawl: {
        toilet: "No",
        hygiene: "No",
        drinking_water: "No",
        ac: "No",
      },
      successMsg: "",
      errorMsg: "",
      _id: "",
      servicesAssigns: [],
      errors: "",
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
    this.fetchStates();
    this.getUserviceAssignResults(userid);
    this.getExpertReview(userid);
  }

  getExpertReview = (id) => {
    const { token } = this.state;

    const { AdminGetAllExpertReview } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminGetAllExpertReview}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          this.setState({
            reviewsResults: data.reviews,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getUserviceAssignResults = (id) => {
    const { token } = this.state;

    const { AdminAssignServicesList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminAssignServicesList}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            servicesAssigns: data.service,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getUserResults = async (userid) => {
    try {
      const { token } = this.state;
      const { AdminExpertUserDetails } = adminendPoints;
      const expertUrl = `${AdminExpertUserDetails}`;
      const contentType = "application/json";
      const body = {
        userid,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );
      const { status, user } = response.data;

      const {
        _id,
        name,
        email,
        phone,
        createdAt,
        updatedAt,
        username,
        state,
        district,
        city,
        working_hours,
        amenities,
        user_profile_images,
        user_work_images,
        homeVisit,
        freelancer,
        gender,
        user_information,
        addresses,
        srviceForMenWomen,
        gstNumber,
        bankAccountNumber,
        aadharNumber,
      } = user;

      const predefinedState = state
        ? { value: state[0]?.state_id, label: state[0]?.state_name }
        : null;

      const predefinedDistrict = district
        ? { value: district[0]?.district_id, label: district[0]?.district_name }
        : null;

      const predefinedCity = city
        ? { value: city[0]?.city_id, label: city[0]?.city_name }
        : null;

      this.setState({
        predefinedState,
        predefinedDistrict,
        predefinedCity,
        _id,
        name,
        email,
        phone,
        createdAt,
        updatedAt,
        username,
        working_hours,
        homeVisit,
        freelancer,
        gender,
        amenities: {
          music: user.amenities?.[0]?.music || "No",
          parking_space: user.amenities?.[0]?.parking_space || "No",
          credit_cards_accepted:
            user.amenities?.[0]?.credit_cards_accepted || "No",
          wi_fi: user.amenities?.[0]?.wi_fi || "No",
          pets_friendly: user.amenities?.[0]?.pets_friendly || "No",
          child_friendly: user.amenities?.[0]?.child_friendly || "No",
          selfie_station: user.amenities?.[0]?.selfie_station || "No",
        },
        aawl: {
          toilet: user.aawl?.[0]?.toilet || "No",
          hygiene: user.aawl?.[0]?.hygiene || "No",
          drinking_water: user.aawl?.[0]?.drinking_water || "No",
          ac: user.aawl?.[0]?.ac || "No",
        },
        user_work_images,
        user_profile_images,
        experience: user_information[0].experience,
        address: addresses && addresses[0] ? addresses[0].address.sector : "",
        pincode: addresses && addresses[0] ? addresses[0].address.pinCode : "",
        srviceForMenWomen: srviceForMenWomen || "",
        aboutMe: user_information?.[0]?.aboutMe || "",
        experience: user_information?.[0]?.experience || "",
        course: user_information?.[0]?.course || "",
        course_name: user_information?.[0]?.course_name || "",
        facebook_url: user_information?.[0]?.facebook_url || "",
        instagram_url: user_information?.[0]?.instagram_url || "",
        gstNumber: gstNumber || "",
        bankAccountNumber: bankAccountNumber || "",
        aadharNumber: aadharNumber || "",
      });
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  fetchStates = async () => {
    const { token } = this.state;
    const { AdminStateList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminStateList}`;

    const statesResponse = await APICaller(
      stateUrl,
      method,
      "",
      contentType,
      token
    );

    const { states } = statesResponse.data;
    const statesData = states.map((state) => ({
      value: state._id,
      label: state.state_name,
    }));

    this.setState({ states: statesData });
  };

  fetchDistrictsByState = async (stateValue) => {
    const { token } = this.state;
    const { AdmingetDistrictByStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const districtUrl = `${AdmingetDistrictByStateName}/${stateValue}`;

    const districtResponse = await APICaller(
      districtUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = districtResponse.data;

    if (status === 200) {
      const districtsData = data.map((district) => ({
        value: district._id,
        label: district.district_name,
      }));

      const selectedDistricts = districtsData || [];
      this.setState({ districts: selectedDistricts, cities: [] });
    } else {
      console.error("Failed to fetch districts");
    }
  };

  fetchCitiesByDistrict = async (districtValue) => {
    const { token } = this.state;
    const { AdmingetCityBYDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const cityUrl = `${AdmingetCityBYDistrictName}/${districtValue}`;

    const cityResponse = await APICaller(
      cityUrl,
      method,
      "",
      contentType,
      token
    );

    const { data, status } = cityResponse.data;

    if (status === 200) {
      const citiesData = data.map((city) => ({
        value: city._id,
        label: city.city_name,
      }));

      const selectedCities = citiesData || [];
      this.setState({ cities: selectedCities });
    } else {
      console.error("Failed to fetch cities");
    }
  };

  renderWorkingHours = (working_hours) => {
    if (!working_hours || !Array.isArray(working_hours)) {
      return null;
    }

    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    return (
      <ul className="availability-bus">
        {daysOfWeek.map((day) => {
          const dayInfo = working_hours.find(
            (item) => item[day] && item[day].open !== undefined
          );

          if (!dayInfo || !dayInfo[day]) {
            return (
              <li key={day}>
                <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
                <span>Closed</span>
              </li>
            );
          }

          const { open, from, to } = dayInfo[day];

          return (
            <li
              key={day}
              className={open.toLowerCase() === "yes" ? "ava-active" : ""}
            >
              <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
              <span>
                {open.toLowerCase() === "yes" ? `${from} - ${to}` : "Closed"}
              </span>
            </li>
          );
        })}
      </ul>
    );
  };

  renderAmenities = (amenities, aawl) => {
    const allAmenities = [
      {
        name: "music",
        label: "Music",
        img: "music-icon.png",
      },
      {
        name: "parking_space",
        label: "Parking Space",
        img: "parking.png",
      },
      {
        name: "credit_cards_accepted",
        label: "Credit Cards Accepted",
        img: "music-icon.png",
      },
      {
        name: "wi_fi",
        label: "Wi-Fi",
        img: "wifi.png",
      },
      {
        name: "pets_friendly",
        label: "Pets Friendly",
        img: "pets.png",
      },
      {
        name: "child_friendly",
        label: "Child Friendly",
        img: "music-icon.png",
      },
      {
        name: "selfie_station",
        label: "Selfie Station",
        img: "selfie.png",
      },
    ];

    const aawlFields = [
      {
        name: "toilet",
        label: "Toilet",
        img: "music-icon.png",
      },
      {
        name: "drinking_water",
        label: "Drinking Water",
        img: "music-icon.png",
      },
      {
        name: "ac",
        label: "AC",
        img: "music-icon.png",
      },
    ];

    const combinedAmenities = [...allAmenities, ...aawlFields];

    return (
      <ul className="Amenities-bus">
        {combinedAmenities.map((amenity) => (
          <li
            key={amenity.name}
            className={
              amenities[amenity.name] === "Yes" || aawl[amenity.name] === "Yes"
                ? "amn-active"
                : ""
            }
          >
            <i className="fa fa-check" aria-hidden="true"></i>
            <div className="amnt-content">
              <span>{amenity.label}</span>
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/${amenity.img}`}
                alt={amenity.label}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  };

  renderReviews = (reviewsResults) => {
    return (
      <div className="testimonials">
        {reviewsResults.map((review) => {
          const profileImage =
            review.userId.user_profile_images.length > 0
              ? review.userId.user_profile_images[0]
              : review.featured_image_url
              ? `${review.featured_image_url}/default-profile.png`
              : "/assets/img/dummy-user.png";

          return (
            <div key={review._id} className="testimonials-col">
              <div className="test-head">
                <div className="test-title">
                  <img src={profileImage} alt="User Profile" />
                  <div className="testimonial-name">
                    <span className="test-name">{review.userId.name}</span>
                    <span>
                      {new Date(review.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <div className="rateing">
                  {[...Array(review.star_rating)].map((_, index) => (
                    <i
                      key={index}
                      className="fa fa-star"
                      aria-hidden="true"
                    ></i>
                  ))}
                  {[...Array(5 - review.star_rating)].map((_, index) => (
                    <i
                      key={index + review.star_rating}
                      className="fa fa-star-o"
                      aria-hidden="true"
                    ></i>
                  ))}
                </div>
              </div>
              <div className="test-head">{review.review}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      selectedState,
      selectedDistrict,
      selectedCity,
      states,
      districts,
      cities,
      predefinedState,
      predefinedDistrict,
      predefinedCity,
      name,
      email,
      createdAt,
      updatedAt,
      phone,
      username,
      password,
      working_hours,
      amenities,
      aawl,
      servicesAssigns,
      _id,
      user_work_images,
      user_profile_images,
      reviewsResults,
      homeVisit,
      freelancer,
      gender,
      address,
      pincode,
      srviceForMenWomen,
      aboutMe,
      experience,
      course,
      course_name,
      facebook_url,
      instagram_url,
      gstNumber,
      aadharNumber,
    } = this.state;

    const featuredImage =
      user_profile_images &&
      user_profile_images.find(
        (image) => Number(image.is_featured) === 1 // Convert to number for comparison
      );

    return (
      <div className="main d-flex custom-cat-list">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex mb-3">
                  <div className="left-head">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Service Management</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Service Management</a>
                      </li>
                      <li className="breadcrumb-item active">{name}</li>
                    </ol>
                    <div className="user-profile">
                      <div className="u-thumb">
                        <img
                          className="w-100"
                          src="../assets/img/dummy-user.png"
                          alt=""
                          title=""
                        />
                      </div>
                      <div className="user-info">
                        <strong className="tc-6">{name}</strong>
                        <span>Customer Since:{formatDate(createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="right-head ms-auto">
                    <div className="head-btns">
                      <button type="button" className="btn-blue ms-2">
                        APPROVE
                      </button>
                      <button
                        type="button"
                        className="btn-light1 restrict-btn ms-2"
                      >
                        <i className="fa fa-ban me-2"></i>Restrict
                      </button>
                      <button
                        type="button"
                        className="btn-light1 ms-2 pink-btn"
                      >
                        <i className="fa fa-trash me-2"></i>Remove
                      </button>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        70%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <a href="#" className="active">
                    Personal Information
                  </a>
                  <a href="#">Appointments</a>
                  <a href="#">Campaign</a>
                  <a href="#">Offer</a>
                  <a href="#">Packages</a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>Profile Information</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />
                        Edit
                      </a>
                    </div>

                    <form className="form-area1 row g-4">
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Stylist Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name"
                          name="name"
                          value={name}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          name="email"
                          value={email}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Home Visit
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="homeVisit"
                          placeholder="Email"
                          name="homeVisit"
                          value={homeVisit}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Are you a Freelancer
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="freelancer"
                          placeholder="freelancer"
                          name="freelancer"
                          value={freelancer}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Username"
                          name="username"
                          value={username}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Gender
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gender"
                          placeholder="gender"
                          name="gender"
                          value={gender}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Experience
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="experience"
                          placeholder="experience"
                          name="experience"
                          value={experience}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Select State:
                        </label>
                        <Select
                          value={selectedState || predefinedState}
                          options={states}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Select District:
                        </label>
                        <Select
                          value={selectedDistrict || predefinedDistrict}
                          options={districts}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Select City:
                        </label>
                        <Select
                          value={selectedCity || predefinedCity}
                          onChange={this.handleCityChange}
                          options={cities}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Pincode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="pincode"
                          placeholder="pincode"
                          name="pincode"
                          value={pincode}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Mobile
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="phone"
                          name="phone"
                          value={phone}
                        />
                      </div>

                      <div className="col-lg-12">
                        <label for="" className="form-label">
                          Salon Address
                        </label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholderaddress
                            name="address"
                            value={address}
                          />
                          <div className="password-icon1">
                            <i className="fa-regular fa-eye-slash"></i>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>Amenities</h4>
                        <span className="last-date">
                          Last Updated: 23, May 2023
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img
                          src="assets/img/icon-edit.svg"
                          alt="Edit Icon"
                          title="Edit"
                        />
                        Edit
                      </a>
                    </div>
                    {this.renderAmenities(amenities, aawl)}
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>Availability</h4>
                        <span className="last-date">
                          Last Updated: 23, May 2023
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    {this.renderWorkingHours(working_hours)}
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>{name} Business Photos</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    {servicesAssigns.map((serviceAssign, index) => (
                      <div key={index}>
                        <div className="treatmet-title">
                          <span>{serviceAssign.sub_service_name}</span>
                          <a href="#">View More</a>
                        </div>
                        <div className="images-bus">
                          <img
                            src={`https://mystylist-media.s3.amazonaws.com/${serviceAssign.fileName}`}
                            alt={serviceAssign.sub_service_name}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>Profile Information</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    <form className="form-area1 row g-4 info-form">
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Services provided to
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="srviceForMenWomen"
                          value={srviceForMenWomen}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          GST Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gstNumber"
                          value={gstNumber}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Aadhar Number
                        </label>
                        <input
                          type="aadharNumber"
                          className="form-control"
                          id="aadharNumber"
                          value={aadharNumber}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Any Course done
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="course"
                          value={course}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Course Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="course_name"
                          value={course_name}
                          disabled
                        />
                      </div>

                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Facebook URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="facebook_url"
                          value={facebook_url}
                          disabled
                        />
                      </div>

                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Instagram URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="instagram_url"
                          value={instagram_url}
                          disabled
                        />
                      </div>

                      <div className="col-lg-12">
                        <label for="" className="form-label">
                          About Me
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="aboutMe"
                          value={aboutMe}
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>{name} Services</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    <ul class="services-bus">
                      {servicesAssigns.map((serviceAssign, index) => (
                        <li key={index} className="ava-active">
                          <div className="ser-con">
                            <span>
                              {serviceAssign.service_name} -{" "}
                              {serviceAssign.sub_service_name}
                            </span>
                            {serviceAssign.best_service && (
                              <p className="tc-6 m-0">
                                {serviceAssign.best_service}
                              </p>
                            )}
                            <span>&#8377;{serviceAssign.price}</span>
                          </div>
                          <img
                            src={`https://mystylist-media.s3.amazonaws.com/${serviceAssign.fileName}`}
                            alt={serviceAssign.sub_service_name}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>{name} Salon Photos</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    <div className="images-bus">
                      {user_work_images?.map((image, index) => (
                        <img
                          key={index}
                          src={`https://mystylist-media.s3.amazonaws.com/${image.image}`}
                          className="img-fluid mb-3"
                          alt={image.image}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-body-head">
                      <div className="card-head-title">
                        <h4>Review</h4>
                        <span className="last-date">
                          Last Updated: {formatDate(updatedAt)}
                        </span>
                      </div>
                      <a href="" className="edit">
                        <img src="assets/img/icon-edit.svg" alt="" title="" />{" "}
                        Edit
                      </a>
                    </div>
                    {this.renderReviews(reviewsResults)}

                    <div className="test-btn">
                      <button type="button" className="btn-blue ms-2">
                        See All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LargeForm;
