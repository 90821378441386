import ActionTypes from "../constants";

export const storeAdminDetail = (param) => ({
  type: ActionTypes.STORE_ADMIN_DETAIL,
  payload: param,
});

export const adminLogout = () => ({
  type: ActionTypes.ADMIN_LOGOUT,
});
export const adminLogin = () => ({
  type: ActionTypes.ADMIN_LOGIN,
});
