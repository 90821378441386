import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditFaq extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      FaqTitle: "",
      FaqAnswer: "",
      status: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      results: "",
      id: "",
    };
  }

  componentDidMount() {
    const faqid = window.location.pathname.split("/").pop();
    this.getFaqResults(faqid);
  }

  getFaqResults = (id) => {
    const { token } = this.state;
    const { AdminFaqDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminFaqDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data,
            FaqTitle: data.FaqTitle,
            FaqAnswer: data.FaqAnswer,
            id: data._id,
            status: data.status,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { FaqTitle, FaqAnswer, status } = this.state;
    const newErrors = {};

    if (!FaqTitle) {
      newErrors.FaqTitle = "Faq Title is required.";
    }

    if (!status) {
      newErrors.status = "status is required.";
    }

    if (!FaqAnswer) {
      newErrors.FaqAnswer = "Faq Answer is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { FaqTitle, FaqAnswer, token, id, status } = this.state;
      const { AdminEditFaq } = adminendPoints;
      const method = "POST";

      const body = {
        id,
        FaqTitle,
        FaqAnswer,
        status,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminEditFaq,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Faq updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      FaqTitle,
      FaqAnswer,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      status,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageFaqs" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit FAQ</h4>
                </div>
                <div className="ms-auto">
                  <Link to="/manageFaqs" className="link">
                    <img
                      className="me-2"
                      src="assets/img/icon-edit1.svg"
                      alt=""
                      title=""
                    />
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alert!
                    </h5>
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5>
                      <i className="icon fas fa-check"></i> Alert!
                    </h5>
                    {successMsg}
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="FaqTitle">Faq Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="FaqTitle"
                        placeholder="Faq Title"
                        name="FaqTitle"
                        value={FaqTitle}
                        onChange={this.handleInputChange}
                      />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="FaqAnswer">Faq Answer</label>
                    <input
                        type="text"
                        className="form-control"
                        id="FaqAnswer"
                        placeholder="Faq Answer"
                        name="FaqAnswer"
                        value={FaqAnswer}
                        onChange={this.handleInputChange}
                      />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>

                    <select
                      name="status"
                      className="form-select"
                      id="status"
                      onChange={this.handleInputChange}
                      aria-label="Default select example"
                    >
                      <option value="">Select Status</option>
                      <option value="Active" selected={status === "Active"}>
                        Active
                      </option>
                      <option
                        value="In-Active"
                        selected={status === "In-Active"}
                      >
                        In-Active
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditFaq);
