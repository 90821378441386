import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditCampaign extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      title: "",
      description: "",
      categories: [{ category_id: "", category_name: "", price: "" }],
      campaignCode: "",
      startDate: "",
      endDate: "",
      successMsg: "",
      errorMsg: "",
      token: retrievedObject ? retrievedObject.token : "",
      errors: {},
      categoryData: [],
      id: "",
    };
  }

  componentDidMount() {
    const campaignid = window.location.pathname.split("/").pop();
    this.getCampaignResults(campaignid);
    this.getAllCategories();
  }

  getCampaignResults = (id) => {
    const { token } = this.state;

    const { AdminCampaignDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdminCampaignDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data; // Update this line

        if (status === 200) {
          const formattedStartDate = data.startDate
            ? data.startDate.substring(0, 10)
            : "";

          const formattedEndDate = data.endDate
            ? data.endDate.substring(0, 10)
            : "";

          this.setState({
            results: data,
            title: data.title,
            description: data.description,
            categories: data.categories,
            campaignCode: data.campaignCode,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            id: data._id,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  getAllCategories = () => {
    const { token } = this.state;
    const { AdminGetAllCategories } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminGetAllCategories}`;
    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            categoryData: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { title, description, campaignCode, startDate, endDate, categories } =
      this.state;
    const newErrors = {};

    if (!title) {
      newErrors.title = "Campaign Title is required.";
    }

    if (!description) {
      newErrors.description = "Description is required.";
    }

    if (!campaignCode) {
      newErrors.campaignCode = "Campaign Code is required.";
    }

    if (!startDate) {
      newErrors.startDate = "Start Date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End Date is required.";
    }

    categories.forEach((category, index) => {
      if (!category.category_id) {
        newErrors[`category_id-${index}`] = "Category is required.";
      }
      if (!category.price) {
        newErrors[`price-${index}`] = "Price is required.";
      }
    });

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const {
        title,
        description,
        categories,
        campaignCode,
        startDate,
        endDate,
        token,
        status,
        id,
      } = this.state;

      const { AdminEditCampaign } = adminendPoints;
      const method = "POST";

      const body = {
        id,
        title,
        description,
        categories,
        campaignCode,
        startDate,
        endDate,
        status,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminEditCampaign,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Campaign updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This Campaign is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update Campaign. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error creating campaign:", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the campaign. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCategoryChange = (e, index) => {
    const { name, value } = e.target;
    let categories = [...this.state.categories];

    if (name === "category_id") {
      const selectedCategory = this.state.categoryData.find(
        (category) => category._id === value
      );
      console.log("Selected Category: ", selectedCategory);

      if (selectedCategory) {
        categories[index] = {
          ...categories[index],
          category_id: value,
          category_name: selectedCategory.name,
        };
      } else {
        categories[index] = { ...categories[index], category_id: value };
      }
    } else {
      categories[index] = { ...categories[index], [name]: value };
    }

    console.log("Categories after change: ", categories);
    this.setState({ categories });
  };

  removeCategory = (index) => {
    const { categories } = this.state;
    categories.splice(index, 1);
    this.setState({ categories });
  };

  addCategory = () => {
    this.setState((prevState) => ({
      categories: [...prevState.categories, { category_id: "", price: "" }],
    }));
  };

  render() {
    const {
      title,
      description,
      categories,
      campaignCode,
      startDate,
      endDate,
      errors,
      successMsg,
      errorMsg,
      token,
      categoryData,
      redirect,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageCampaigns" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Update Campaign</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Update Campaign</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Update Campaign</h3>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      {errorMsg && (
                        <div className="alert alert-danger alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          <h5>
                            <i className="icon fas fa-ban"></i> Alert!
                          </h5>
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                          <h5>
                            <i className="icon fas fa-check"></i> Alert!
                          </h5>
                          {successMsg}
                        </div>
                      )}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="title">Campaign Title</label>
                              <input
                                placeholder="Campaign Title"
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={title}
                                onChange={this.handleInputChange}
                              />
                              {errors.title && (
                                <p className="error">{errors.title}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="title">Campaign Code</label>
                              <input
                                placeholder="Campaign Code"
                                type="text"
                                className="form-control"
                                id="campaignCode"
                                name="campaignCode"
                                value={campaignCode}
                                onChange={this.handleInputChange}
                              />
                              {errors.campaignCode && (
                                <p className="error">{errors.campaignCode}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="startDate">Start Date</label>
                              <input
                                placeholder="Start Date"
                                type="date"
                                className="form-control"
                                id="startDate"
                                name="startDate"
                                value={startDate}
                                onChange={this.handleInputChange}
                              />
                              {errors.startDate && (
                                <p className="error">{errors.startDate}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="endDate">End Date</label>
                              <input
                                placeholder="End Date"
                                type="date"
                                className="form-control"
                                id="endDate"
                                name="endDate"
                                value={endDate}
                                onChange={this.handleInputChange}
                              />
                              {errors.endDate && (
                                <p className="error">{errors.endDate}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <textarea
                                placeholder="Description"
                                className="form-control"
                                id="description"
                                name="description"
                                value={description}
                                onChange={this.handleInputChange}
                              />
                              {errors.description && (
                                <p className="error">{errors.description}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            {categories.map((category, index) => (
                              <div key={index}>
                                <div className="form-group">
                                  <label>Category:</label>
                                  <select
                                    className="form-control"
                                    name="category_id"
                                    value={category.category_id}
                                    onChange={(e) =>
                                      this.handleCategoryChange(e, index)
                                    }
                                  >
                                    <option value="" disabled>
                                      Select a category
                                    </option>
                                    {categoryData.map((categoryOption) => (
                                      <option
                                        key={categoryOption._id}
                                        value={categoryOption._id}
                                      >
                                        {categoryOption.name}
                                      </option>
                                    ))}
                                  </select>

                                  {errors[`category_id-${index}`] && (
                                    <p className="error">
                                      {errors[`category_id-${index}`]}
                                    </p>
                                  )}
                                </div>

                                <div className="form-group">
                                  <label>Price:</label>
                                  <input
                                    placeholder="Price"
                                    type="number"
                                    className="form-control"
                                    name="price"
                                    value={category.price}
                                    onChange={(e) =>
                                      this.handleCategoryChange(e, index)
                                    }
                                  />{" "}
                                  {errors[`price-${index}`] && (
                                    <p className="error">
                                      {errors[`price-${index}`]}
                                    </p>
                                  )}
                                </div>

                                {index > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => this.removeCategory(index)}
                                  >
                                    Remove Category
                                  </button>
                                )}
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.addCategory}
                            >
                              Add Category
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditCampaign);
