import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class EditCategory extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      category_name: "",
      status: "",
      fileName: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
      selectedFile: null,
      previewURL: null,
      results: "",
      id: "",
    };
  }

  componentDidMount() {
    const catid = window.location.pathname.split("/").pop();
    this.getLeaveResults(catid);
  }

  getLeaveResults = (id) => {
    const { token } = this.state;
    const { AdminCategoryDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminCategoryDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            results: data,
            category_name: data.category_name,
            id: data._id,
            status: data.status,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  validateForm = () => {
    const { category_name, selectedFile, status } = this.state;
    const newErrors = {};

    if (!category_name) {
      newErrors.category_name = "Category Name is required.";
    }

    if (!status) {
      newErrors.status = "status is required.";
    }

    if (!selectedFile) {
      newErrors.fileName = "File is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { category_name, selectedFile, token, id, status } = this.state;

      const { AdminEditCategory } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("category_name", category_name);
      formData.append("status", status);
      formData.append("fileName", selectedFile);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminEditCategory,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Category updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This category name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "", // Clear the fileName state
    });
  };

  render() {
    const {
      category_name,
      errors,
      redirect,
      successMsg,
      errorMsg,
      token,
      previewURL,
      status,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageCategory" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Edit Category</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Edit Category</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header card-header-tool-align">
                    <h3 className="card-title">Edit Category</h3>
                    <div className="card-tools">
                      <Link
                        to="/ManageCategory"
                        className="btn btn-sm btn-success"
                      >
                        Manage Category
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      {errorMsg && (
                        <div className="alert alert-danger alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          <h5>
                            <i className="icon fas fa-ban"></i> Alert!
                          </h5>
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                          <h5>
                            <i className="icon fas fa-check"></i> Alert!
                          </h5>
                          {successMsg}
                        </div>
                      )}

                      {errors && (
                        <div className="callout callout-danger">
                          <h5>All * fields are required</h5>
                          {errors.category_name && (
                            <p className="error">{errors.category_name}</p>
                          )}
                          {errors.fileName && (
                            <p className="error">{errors.fileName}</p>
                          )}
                          {errors.status && (
                            <p className="error">{errors.status}</p>
                          )}
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="category_name">Category Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="category_name"
                              placeholder="Category Name"
                              name="category_name"
                              value={category_name}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                              name="status"
                              className="custom-select rounded-0"
                              id="status"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Status</option>
                              <option
                                value="Active"
                                selected={status === "Active"}
                              >
                                Active
                              </option>
                              <option
                                value="In-Active"
                                selected={status === "In-Active"}
                              >
                                In-Active
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="fileName">Category Image</label>
                            <input
                              type="file"
                              className="form-control"
                              id="fileName"
                              placeholder="Absence through"
                              name="fileName"
                              accept="image/*"
                              onChange={this.handleFileSelect}
                            />
                            {previewURL && (
                              <div>
                                <img
                                  src={previewURL}
                                  alt="Category Preview"
                                  style={{ width: "100px", height: "100px" }}
                                />
                                <button
                                  type="button"
                                  onClick={this.handleFileRemove}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(EditCategory);
