import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddBanner extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      startDate: "",
      endDate: "",
      position: "",
      description: "",
      banner_name: "",
      fileName: "",
      selectedFile: null,
      previewURL: null,
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      districtResults: [],
      token: retrievedObject ? retrievedObject.token : "",
      status: "In-Active",
      loading: false,
      banner_type: "",
    };
  }

  validateForm = () => {
    const { banner_name, position, selectedFile, banner_type } = this.state;
    const newErrors = {};

    if (!banner_name) {
      newErrors.banner_name = "Banner Name is required.";
    }

    if (!position) {
      newErrors.position = "Position is required.";
    }

    if (!banner_type) {
      newErrors.banner_type = "Banner type is required.";
    }

    if (!selectedFile) {
      newErrors.fileName = "File is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e, reloadListing) => {
    e.preventDefault();

    const newErrors = this.validateForm();
    this.setState({ loading: true });
    if (Object.keys(newErrors).length > 0) {
      this.setState(
        { errorMsg: "", successMsg: "", errors: newErrors, loading: false },
        () => {
          window.setTimeout(() => {
            this.setState({ errors: {} });
          }, 5000);
        }
      );
    } else {
      this.setState({ errors: {} });
      const {
        banner_name,
        selectedFile,
        token,
        description,
        startDate,
        endDate,
        position,
        banner_type,
      } = this.state;

      const { AdminAddBanner } = adminendPoints;
      const formData = new FormData();
      formData.append("banner_type", banner_type);
      formData.append("banner_name", banner_name);
      formData.append("fileName", selectedFile);
      formData.append("description", description);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("position", position);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminAddBanner,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Services created successfully.",
              errorMsg: "",
            },
            () => {
              this.setState({
                banner_type: "",
                banner_name: "",
                selectedFile: null,
                previewURL: null,
                fileName: "",
                description: "",
                loading: false,
                startDate: "",
                endDate: "",
                position: "",
              });
              reloadListing();
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
                if (this.props.onCategoryInsert) {
                  this.props.onCategoryInsert();
                }
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This service name is already exist",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create service. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the service. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handlePositionChange = (e) => {
    this.setState({ position: e.target.value });
  };

  handleBannerTypeChange = (e) => {
    this.setState({ banner_type: e.target.value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name, // Set the fileName state to the actual file name
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  render() {
    const {
      previewURL,
      banner_name,
      startDate,
      endDate,
      position,
      errors,
      successMsg,
      errorMsg,
      token,
      description,
      status,
      loading,
      banner_type,
    } = this.state;

    const { isVisibleAdd, handleCloseAddContent, reloadListing } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Banner Information</h4>
                    <p className="mb-0 tc-5">Add Banner</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={(e) => this.handleSubmit(e, reloadListing)}
                >
                  {errorMsg && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsg}
                    </div>
                  )}

                  {successMsg && (
                    <div className="alert alert-success" role="alert">
                      {successMsg}
                    </div>
                  )}

                  {loading && (
                    <div className="d-flex justify-content-center align-items-center loader">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col">
                      <div className="upload-img-box">
                        <i className="fa-solid fa-file-arrow-up"></i>
                        <p className="mb-0">Upload Image</p>
                        <input
                          type="file"
                          className="form-control"
                          id="fileName"
                          placeholder="Absence through"
                          name="fileName"
                          accept="image/*"
                          onChange={this.handleFileSelect}
                        />
                        {previewURL && (
                          <div>
                            <img
                              src={previewURL}
                              alt="Banner Preview"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <button
                              type="button"
                              onClick={this.handleFileRemove}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <label htmlFor="banner_name" className="form-label">
                        Banner Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="banner_name"
                        placeholder="Banner Name"
                        name="banner_name"
                        value={banner_name}
                        onChange={this.handleInputChange}
                      />
                      {errors && (
                        <div className="callout callout-danger">
                          {errors.banner_name && (
                            <p className="error">{errors.banner_name}</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col">
                      <label htmlFor="Position" className="form-label">
                        Position
                      </label>
                      <select
                        className="form-select"
                        id="position"
                        aria-label="Default select example"
                        value={position}
                        onChange={this.handlePositionChange}
                      >
                        <option value="">Select Position</option>
                        <option value="Top">Top</option>
                        <option value="Middle">Middle</option>
                        <option value="Bottom">Bottom</option>
                      </select>
                      {errors.position && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.position}</p>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="banner_type" className="form-label">
                        Banner Type
                      </label>
                      <select
                        className="form-select"
                        id="banner_type"
                        aria-label="Default select example"
                        value={banner_type}
                        onChange={this.handleBannerTypeChange}
                      >
                        <option value="">Select Banner Type</option>
                        <option value="Home">Home</option>
                        <option value="Offer">Offer</option>
                        <option value="Package">Package</option>
                        <option value="Appointments">Appointments</option>
                      </select>
                      {errors.position && (
                        <div className="callout callout-danger">
                          <p className="error">{errors.banner_type}</p>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Service Description"
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <input
                        type="Date"
                        className="form-control"
                        id="startDate"
                        placeholder="Start Name"
                        name="startDate"
                        value={startDate}
                        onChange={this.handleInputChange}
                      />
                      {errors && (
                        <div className="callout callout-danger">
                          {errors.startDate && (
                            <p className="error">{errors.startDate}</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <input
                        type="Date"
                        className="form-control"
                        id="endDate"
                        placeholder="End Name"
                        name="endDate"
                        value={endDate}
                        onChange={this.handleInputChange}
                      />
                      {errors && (
                        <div className="callout callout-danger">
                          {errors.endDate && (
                            <p className="error">{errors.endDate}</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      <button type="submit" className="btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddBanner);
