import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddExpert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      mobile_type: "",
      name: "",
      email: "",
      phone: "",
      role: "",
      username: "",
      password: "",
      business_type: "",
      gender: "",
      birthday: "",
      selectedState: "",
      selectedDistrict: "",
      selectedCity: "",
      stateResults: [],
      districts: [],
      cities: [],
      user_category_assign: [],
      categories: [],
      errors: {},
      token: getLocalStorageItem("myStylistAdmin")?.token || "",
      addresses: [
        {
          pinCode: "",
          landmark: "",
          location: { type: "Point", coordinates: [] },
        },
      ],
      aawl: [{ toilet: "", hygiene: "", drinking_water: "", ac: "" }],
      amenities: [
        {
          parking_space: "",
          music: "",
          credit_cards_accepted: "",
          wi_fi: "",
          pets_friendly: "",
          child_friendly: "",
          selfie_station: "",
        },
      ],
      working_hours: [
        {
          sunday: { open: "", from: "", to: "" },
          monday: { open: "", from: "", to: "" },
          tuesday: { open: "", from: "", to: "" },
          wednesday: { open: "", from: "", to: "" },
          thursday: { open: "", from: "", to: "" },
          friday: { open: "", from: "", to: "" },
          saturday: { open: "", from: "", to: "" },
        },
      ],
      user_information: [
        {
          experience: "",
          course: "",
          course_name: "",
          facebook_url: "",
          instagram_url: "",
        },
      ],
      successMsg: "",
      errorMsg: "",
      redirect: false,
    };
    this.daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  }

  componentDidMount() {
    this.getStateResults();
    const fetchedCategories = [
      { _id: "6495380aaef2af6a18edfab5", name: "Category 1" },
      { _id: "6495380aaef2af6a18edfab5", name: "Category 2" },
    ];
    this.setState({ categories: fetchedCategories });
  }

  getStateResults = () => {
    const { token } = this.state;
    const { AdminStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminStateName}`;
    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;

        if (status === 200) {
          this.setState({
            stateResults: data,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  fetchDistricts = (selectedState) => {
    const { token } = this.state;
    const { AdmingetDistrictByStateName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdmingetDistrictByStateName}/${selectedState}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          this.setState({
            districts: data,
            selectedDistrict: "",
            cities: [],
            selectedCity: "",
          });
        } else {
          console.log("Error fetching districts data");
        }
      })
      .catch((error) => {
        console.log("Error fetching districts data: ", error);
      });
  };

  fetchCities = (selectedDistrict) => {
    const { token } = this.state;
    const { AdmingetCityBYDistrictName } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    const url = `${AdmingetCityBYDistrictName}/${selectedDistrict}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          this.setState({
            cities: data,
            selectedCity: "",
          });
        } else {
          console.log("Error fetching cities data");
        }
      })
      .catch((error) => {
        console.log("Error fetching cities data: ", error);
      });
  };

  handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;

    this.setState({
      selectedDistrict: selectedDistrictId,
    });

    if (selectedDistrictId) {
      this.fetchCities(selectedDistrictId);
    }
  };

  handleAddCategoryAssign = () => {
    this.setState((prevState) => ({
      user_category_assign: [
        ...prevState.user_category_assign,
        {
          category_id: "",
          category_name: "",
          price: "",
          best_services: "Yes",
        },
      ],
    }));
  };

  handleRemoveCategoryAssign = (index) => {
    this.setState((prevState) => ({
      user_category_assign: prevState.user_category_assign.filter(
        (_, i) => i !== index
      ),
    }));
  };

  handleCategoryChange = (e, index) => {
    const { value } = e.target;

    this.setState((prevState) => ({
      user_category_assign: prevState.user_category_assign.map((item, i) =>
        i === index
          ? {
              ...item,
              category_id: value, // Use the selected value as category_id
              category_name: this.state.categories.find(
                (category) => category._id === value
              )?.name,
            }
          : item
      ),
    }));
  };

  handleAddressInputChange = (e, index) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedAddresses = [...prevState.addresses];
      updatedAddresses[index][name] = value;
      return { addresses: updatedAddresses };
    });
  };

  handleCoordinatesChange = (e, index) => {
    const { name, value } = e.target;
    const coordinates = value.split(",").map(Number);

    this.setState((prevState) => {
      const updatedAddresses = prevState.addresses.map((item, i) =>
        i === index
          ? { ...item, location: { ...item.location, [name]: coordinates } }
          : item
      );

      return { addresses: updatedAddresses };
    });
  };

  handleWorkingHoursChange = (e, group, index) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedGroup = [...prevState[group]];
      const day = name.split(".")[0];
      const field = name.split(".")[1];

      if (field === "open" && value === "No") {
        updatedGroup[index][day].from = "";
        updatedGroup[index][day].to = "";
      }

      updatedGroup[index][day][field] = value;

      return { [group]: updatedGroup };
    });
  };

  handleInputChange = (e, group, index, field) => {
    const { name, value } = e.target;

    if (name === "selectedState") {
      this.fetchDistricts(value);
    }

    if (group !== undefined && index !== undefined && field) {
      this.setState((prevState) => {
        const updatedGroup = [...prevState[group]];
        updatedGroup[index] = {
          ...updatedGroup[index],
          [field]: value,
        };

        return { [group]: updatedGroup };
      });
    } else {
      this.setState({
        [e.target.name]: value,
      });
    }
  };

  handleAawlChange = (e, field) => {
    const { value } = e.target;

    this.setState((prevState) => {
      const updatedAawl = [{ ...prevState.aawl[0], [field]: value }];
      return { aawl: updatedAawl };
    });
  };

  validateForm = () => {
    const {
      aawl,
      amenities,
      working_hours,
      user_information,
      selectedState,
      selectedDistrict,
      selectedCity,
      name,
      email,
      phone,
      role,
      username,
      password,
      business_type,
      gender,
      birthday,
      mobile_type,
      status,
      user_category_assign,
    } = this.state;

    const errors = {};

    // Validate user_category_assign
    user_category_assign.forEach((category, index) => {
      if (!category.category_id) {
        errors[`category_id_${index}`] = "Category is required";
      }
      if (!category.price) {
        errors[`price_${index}`] = "Price is required";
      }
    });

    const addressErrors = this.state.addresses.map((address, index) => {
      const addressError = {};
      if (!address.pinCode) {
        addressError.pinCode = "Pin Code is required";
      }
      if (!address.landmark) {
        addressError.landmark = "Landmark is required";
      }
      if (
        address.location.coordinates.length !== 2 ||
        isNaN(address.location.coordinates[0]) ||
        isNaN(address.location.coordinates[1])
      ) {
        addressError.coordinates = "Invalid Coordinates";
      }
      return addressError;
    });

    addressErrors.forEach((addressError, index) => {
      if (Object.keys(addressError).length > 0) {
        errors[`pinCode_${index}`] = addressError.pinCode;
        errors[`landmark_${index}`] = addressError.landmark;
        errors[`coordinates_${index}`] = addressError.coordinates;
      }
    });

    const aawlErrors = {};
    if (!aawl[0].toilet) {
      aawlErrors.toilet = "Toilet field is required";
    }
    if (!aawl[0].hygiene) {
      aawlErrors.hygiene = "Hygiene field is required";
    }

    if (!aawl[0].drinking_water) {
      aawlErrors.drinking_water = "Drinking water field is required";
    }

    if (!aawl[0].ac) {
      aawlErrors.ac = "AC field is required";
    }

    Object.assign(errors, aawlErrors);

    const amenitiesErrors = {};
    if (!amenities[0].parking_space) {
      amenitiesErrors.parking_space = "Parking Space field is required";
    }
    if (!amenities[0].music) {
      amenitiesErrors.music = "Music field is required";
    }

    if (!amenities[0].credit_cards_accepted) {
      amenitiesErrors.credit_cards_accepted =
        "Credit cards accepted field is required";
    }

    if (!amenities[0].wi_fi) {
      amenitiesErrors.wi_fi = "wi fi field is required";
    }

    if (!amenities[0].pets_friendly) {
      amenitiesErrors.pets_friendly = "Pets friendly field is required";
    }

    if (!amenities[0].child_friendly) {
      amenitiesErrors.child_friendly = "Child friendly field is required";
    }

    if (!amenities[0].selfie_station) {
      amenitiesErrors.selfie_station = "Selfie station field is required";
    }

    Object.assign(errors, amenitiesErrors);

    const workingHoursErrors = {};

    this.daysOfWeek.forEach((day) => {
      if (
        working_hours[0][day.toLowerCase()].open === "Yes" &&
        (!working_hours[0][day.toLowerCase()].from ||
          !working_hours[0][day.toLowerCase()].to)
      ) {
        workingHoursErrors[day.toLowerCase()] =
          "From and To fields are required when the shop is open";
      }
    });

    Object.assign(errors, workingHoursErrors);

    const userInformationErrors = {};

    if (!user_information[0].experience) {
      userInformationErrors.experience = "Experience is required";
    }

    if (user_information[0].course === "") {
      userInformationErrors.course = "Course is required";
    }

    if (
      user_information[0].course === "Yes" &&
      !user_information[0].course_name
    ) {
      userInformationErrors.course_name = "Course Name is required";
    }

    if (!user_information[0].instagram_url) {
      userInformationErrors.instagram_url = "Instagram URL is required";
    }

    Object.assign(errors, userInformationErrors);

    if (!selectedState) {
      errors.selectedState = "Please select a state";
    }

    if (!selectedDistrict) {
      errors.selectedDistrict = "Please select a District";
    }

    if (!selectedCity) {
      errors.selectedCity = "Please select a City";
    }

    if (!email) {
      errors.email = "Please select a email";
    }

    if (!name) {
      errors.name = "Please enter name";
    }

    if (!phone) {
      errors.phone = "Please enter phone number";
    }

    if (!role) {
      errors.role = "Please select role";
    }

    if (!username) {
      errors.username = "Please enter username";
    }

    if (!password) {
      errors.password = "Please enter password";
    }

    if (!business_type) {
      errors.business_type = "Please select business type";
    }

    if (!gender) {
      errors.gender = "Please select gender";
    }

    if (!birthday) {
      errors.birthday = "Please enter birthday";
    }

    if (!mobile_type) {
      errors.mobile_type = "Please select mobile type";
    }

    if (!status) {
      errors.status = "Please select status";
    }

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const {
        aawl,
        amenities,
        working_hours,
        user_information,
        selectedState,
        selectedDistrict,
        selectedCity,
        name,
        email,
        phone,
        role,
        username,
        password,
        business_type,
        gender,
        birthday,
        addresses,
        user_category_assign,
        token,
        mobile_type,
        status,
      } = this.state;

      const cityData = [
        {
          city_id: selectedCity,
          city_name:
            this.state.cities.find((city) => city._id === selectedCity)
              ?.city_name || "",
        },
      ];

      const districtData = [
        {
          district_id: selectedDistrict,
          district_name:
            this.state.districts.find((city) => city._id === selectedDistrict)
              ?.district_name || "",
        },
      ];

      const stateData = [
        {
          state_id: selectedState,
          state_name:
            this.state.stateResults.find((state) => state._id === selectedState)
              ?.state_name || "",
        },
      ];

      const { AdminAddExpert } = adminendPoints;
      const method = "POST";
      const body = {
        aawl,
        amenities,
        working_hours,
        user_information,
        stateData,
        districtData,
        cityData,
        name,
        email,
        phone,
        role,
        username,
        password,
        business_type,
        gender,
        birthday,
        addresses,
        user_category_assign,
        mobile_type,
        status,
      };

      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminAddExpert,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "District created successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This District name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create State. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  render() {
    const {
      name,
      email,
      phone,
      role,
      username,
      password,
      business_type,
      gender,
      selectedState,
      districts,
      cities,
      selectedDistrict,
      selectedCity,
      stateResults,
      working_hours,
      addresses,
      aawl,
      amenities,
      birthday,
      errors,
      user_information,
      user_category_assign,
      mobile_type,
      status,
      redirect,
      successMsg,
      errorMsg,
      token,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    return (
      <div className="wrapper">
        <Header />
        <SideBar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Add Expert</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Expert</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid">
            <div className="">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Manage Experts</h3>
                    <div className="card-tools">
                      <Link
                        to="/ManageExperts"
                        className="btn btn-sm btn-success"
                      >
                        District
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      {errorMsg && (
                        <div className="alert alert-danger alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                          <h5>
                            <i className="icon fas fa-ban"></i> Alert!
                          </h5>
                          {errorMsg}
                        </div>
                      )}
                      {successMsg && (
                        <div className="alert alert-success alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                          <h5>
                            <i className="icon fas fa-check"></i> Alert!
                          </h5>
                          {successMsg}
                        </div>
                      )}
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="selectedState">Select State</label>
                            <select
                              name="selectedState"
                              value={selectedState}
                              className={`custom-select form-control ${
                                errors.selectedState ? "is-invalid" : ""
                              }`}
                              onChange={this.handleInputChange}
                              id="state_id"
                            >
                              <option value="">Select State</option>
                              {stateResults.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.state_name}
                                </option>
                              ))}
                            </select>
                            {errors.selectedState && (
                              <div className="invalid-feedback">
                                {errors.selectedState}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="district">District</label>
                            <select
                              name="selectedDistrict"
                              className={`custom-select form-control ${
                                errors.selectedDistrict ? "is-invalid" : ""
                              }`}
                              id="district"
                              value={selectedDistrict}
                              onChange={this.handleDistrictChange}
                              disabled={!selectedState}
                            >
                              <option value="">Select District</option>
                              {districts.map((district) => (
                                <option key={district._id} value={district._id}>
                                  {district.district_name}
                                </option>
                              ))}
                            </select>
                            {errors.selectedDistrict && (
                              <div className="invalid-feedback">
                                {errors.selectedDistrict}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="city">City</label>
                            <select
                              name="selectedCity"
                              className={`custom-select form-control ${
                                errors.selectedCity ? "is-invalid" : ""
                              }`}
                              id="city"
                              value={selectedCity}
                              onChange={this.handleInputChange}
                              disabled={!selectedDistrict}
                            >
                              <option value="">Select City</option>
                              {cities.map((city) => (
                                <option key={city._id} value={city._id}>
                                  {city.city_name}
                                </option>
                              ))}
                            </select>
                            {errors.selectedCity && (
                              <div className="invalid-feedback">
                                {errors.selectedCity}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              className={`custom-select form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              id="name"
                              placeholder="Name"
                              name="name"
                              value={name}
                              onChange={this.handleInputChange}
                            />
                            {errors.name && (
                              <div className="invalid-feedback">
                                {errors.name}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className={`custom-select form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              id="email"
                              placeholder="email"
                              name="email"
                              value={email}
                              onChange={this.handleInputChange}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                              type="phone"
                              className={`custom-select form-control ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              id="phone"
                              placeholder="phone"
                              name="phone"
                              value={phone}
                              onChange={this.handleInputChange}
                            />
                            {errors.phone && (
                              <div className="invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                              type="text"
                              className={`custom-select form-control ${
                                errors.username ? "is-invalid" : ""
                              }`}
                              id="username"
                              placeholder="Username"
                              name="username"
                              value={username}
                              onChange={this.handleInputChange}
                            />
                            {errors.username && (
                              <div className="invalid-feedback">
                                {errors.username}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                              type="text"
                              className={`custom-select form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              id="password"
                              placeholder="Password"
                              name="password"
                              value={password}
                              onChange={this.handleInputChange}
                            />
                            {errors.password && (
                              <div className="invalid-feedback">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                              name="status"
                              className={`custom-select form-control ${
                                errors.status ? "is-invalid" : ""
                              }`}
                              id="status"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Status </option>
                              <option
                                value="Andriod"
                                selected={status === "Active"}
                              >
                                Active
                              </option>
                              <option
                                value="IOS"
                                selected={status === "In-Active"}
                              >
                                In-Active
                              </option>
                            </select>
                            {errors.status && (
                              <div className="invalid-feedback">
                                {errors.status}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="mobile_type">Mobile Type</label>
                            <select
                              name="mobile_type"
                              className={`custom-select form-control ${
                                errors.role ? "is-invalid" : ""
                              }`}
                              id="mobile_type"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Mobile Type </option>
                              <option
                                value="Andriod"
                                selected={mobile_type === "Andriod"}
                              >
                                Andriod
                              </option>
                              <option
                                value="IOS"
                                selected={mobile_type === "IOS"}
                              >
                                IOS
                              </option>
                            </select>
                            {errors.mobile_type && (
                              <div className="invalid-feedback">
                                {errors.mobile_type}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <select
                              name="role"
                              className={`custom-select form-control ${
                                errors.role ? "is-invalid" : ""
                              }`}
                              id="role"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Role</option>
                              <option value="user" selected={role === "user"}>
                                User
                              </option>
                              <option
                                value="expert"
                                selected={role === "expert"}
                              >
                                Expert
                              </option>
                            </select>
                            {errors.role && (
                              <div className="invalid-feedback">
                                {errors.role}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="business_type">Business Type</label>
                            <select
                              name="business_type"
                              className={`custom-select form-control ${
                                errors.business_type ? "is-invalid" : ""
                              }`}
                              id="business_type"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Business Type</option>
                              <option
                                value="Individual"
                                selected={business_type === "Individual"}
                              >
                                Individual
                              </option>
                              <option
                                value="Salon-Owner"
                                selected={business_type === "Salon-Owner"}
                              >
                                Salon-Owner
                              </option>
                            </select>
                            {errors.business_type && (
                              <div className="invalid-feedback">
                                {errors.business_type}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select
                              name="gender"
                              className={`custom-select form-control ${
                                errors.gender ? "is-invalid" : ""
                              }`}
                              id="gender"
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male" selected={gender === "Male"}>
                                Male
                              </option>
                              <option
                                value="Female"
                                selected={gender === "Female"}
                              >
                                Female
                              </option>
                            </select>
                            {errors.gender && (
                              <div className="invalid-feedback">
                                {errors.gender}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="birthday">Birthday</label>
                            <input
                              type="date"
                              className={`custom-select form-control ${
                                errors.birthday ? "is-invalid" : ""
                              }`}
                              id="birthday"
                              placeholder="birthday"
                              name="birthday"
                              value={birthday}
                              onChange={this.handleInputChange}
                            />
                            {errors.birthday && (
                              <div className="invalid-feedback">
                                {errors.birthday}
                              </div>
                            )}
                          </div>
                        </div>

                        {addresses.map((address, index) => (
                          <div className="col-lg-12 mb-4" key={index}>
                            <div className="row" key={index}>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor={`pinCode_${index}`}>
                                    Pin Code
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors[`pinCode_${index}`]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id={`pinCode_${index}`}
                                    placeholder="Pin Code"
                                    name="pinCode"
                                    value={address.pinCode}
                                    onChange={(e) =>
                                      this.handleAddressInputChange(e, index)
                                    }
                                  />
                                  {errors[`pinCode_${index}`] && (
                                    <div className="invalid-feedback">
                                      {errors[`pinCode_${index}`]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor={`landmark_${index}`}>
                                    Landmark
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors[`landmark_${index}`]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id={`landmark_${index}`}
                                    placeholder="Landmark"
                                    name="landmark"
                                    value={address.landmark}
                                    onChange={(e) =>
                                      this.handleAddressInputChange(e, index)
                                    }
                                  />
                                  {errors[`landmark_${index}`] && (
                                    <div className="invalid-feedback">
                                      {errors[`landmark_${index}`]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor={`coordinates_${index}`}>
                                    Coordinates (latitude, longitude)
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors[`coordinates_${index}`]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id={`coordinates_${index}`}
                                    placeholder="Coordinates"
                                    name="coordinates"
                                    value={address.location.coordinates.join(
                                      ", "
                                    )}
                                    onChange={(e) =>
                                      this.handleCoordinatesChange(e, index)
                                    }
                                  />
                                  {errors[`coordinates_${index}`] && (
                                    <div className="invalid-feedback">
                                      {errors[`coordinates_${index}`]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="toilet">Toilet</label>
                            <select
                              name="toilet"
                              className={`form-control custom-select ${
                                errors["toilet"] ? "is-invalid" : ""
                              }`}
                              value={aawl[0].toilet}
                              onChange={(e) =>
                                this.handleAawlChange(e, "toilet")
                              }
                            >
                              <option value="">Select Value</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors["toilet"] && (
                              <div className="invalid-feedback">
                                {errors["toilet"]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="hygiene">Hygiene</label>
                            <select
                              name="hygiene"
                              className={`form-control custom-select ${
                                errors["hygiene"] ? "is-invalid" : ""
                              }`}
                              value={aawl[0].hygiene}
                              onChange={(e) =>
                                this.handleInputChange(e, "aawl", 0)
                              }
                            >
                              <option value="">Select Value</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors["hygiene"] && (
                              <div className="invalid-feedback">
                                {errors["hygiene"]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="drinking_water">
                              Drinking Water
                            </label>
                            <select
                              name="drinking_water"
                              className={`form-control custom-select ${
                                errors["drinking_water"] ? "is-invalid" : ""
                              }`}
                              value={aawl[0].drinking_water}
                              onChange={(e) =>
                                this.handleInputChange(e, "aawl", 0)
                              }
                            >
                              <option value="">Select Value</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors["drinking_water"] && (
                              <div className="invalid-feedback">
                                {errors["drinking_water"]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="ac">Air Condition</label>
                            <select
                              name="ac"
                              className={`form-control custom-select ${
                                errors["ac"] ? "is-invalid" : ""
                              }`}
                              value={aawl[0].ac}
                              onChange={(e) =>
                                this.handleInputChange(e, "aawl", 0)
                              }
                            >
                              <option value="">Select Value</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {errors["ac"] && (
                              <div className="invalid-feedback">
                                {errors["ac"]}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 mb-4">
                          <h5>Amenities</h5>
                          <hr />
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="parking_space">
                                  Parking Space
                                </label>
                                <select
                                  name="parking_space"
                                  className={`form-control custom-select ${
                                    errors["parking_space"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].parking_space}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["parking_space"] && (
                                  <div className="invalid-feedback">
                                    {errors["parking_space"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="music">Music</label>
                                <select
                                  name="music"
                                  className={`form-control custom-select ${
                                    errors["music"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].music}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["music"] && (
                                  <div className="invalid-feedback">
                                    {errors["music"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="credit_cards_accepted">
                                  Credit Cards Accepted
                                </label>
                                <select
                                  name="credit_cards_accepted"
                                  className={`form-control custom-select ${
                                    errors["credit_cards_accepted"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={amenities[0].credit_cards_accepted}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["credit_cards_accepted"] && (
                                  <div className="invalid-feedback">
                                    {errors["credit_cards_accepted"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="wi_fi">Wi-Fi</label>
                                <select
                                  name="wi_fi"
                                  className={`form-control custom-select ${
                                    errors["wi_fi"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].wi_fi}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["wi_fi"] && (
                                  <div className="invalid-feedback">
                                    {errors["wi_fi"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="pets_friendly">
                                  Pets Friendly
                                </label>
                                <select
                                  name="pets_friendly"
                                  className={`form-control custom-select ${
                                    errors["pets_friendly"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].pets_friendly}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["pets_friendly"] && (
                                  <div className="invalid-feedback">
                                    {errors["pets_friendly"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="child_friendly">
                                  Child Friendly
                                </label>
                                <select
                                  name="child_friendly"
                                  className={`form-control custom-select ${
                                    errors["child_friendly"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].child_friendly}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["child_friendly"] && (
                                  <div className="invalid-feedback">
                                    {errors["child_friendly"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group">
                                <label htmlFor="selfie_station">
                                  Selfie Station
                                </label>
                                <select
                                  name="selfie_station"
                                  className={`form-control custom-select ${
                                    errors["selfie_station"] ? "is-invalid" : ""
                                  }`}
                                  value={amenities[0].selfie_station}
                                  onChange={(e) =>
                                    this.handleInputChange(e, "amenities", 0)
                                  }
                                >
                                  <option value="">Select Value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                {errors["selfie_station"] && (
                                  <div className="invalid-feedback">
                                    {errors["selfie_station"]}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <h5>Working Hours</h5>
                          <hr />
                          <div className="row">
                            {this.daysOfWeek.map((day, index) => (
                              <div className="col-lg-12 mb-2" key={index}>
                                <h5>{day}</h5>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Shop is open?</label>
                                      <select
                                        name={`${day.toLowerCase()}.open`}
                                        className={`custom-select rounded-0 ${
                                          errors[day.toLowerCase()]
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        value={
                                          working_hours[0][day.toLowerCase()]
                                            .open
                                        }
                                        onChange={(e) =>
                                          this.handleWorkingHoursChange(
                                            e,
                                            "working_hours",
                                            0
                                          )
                                        }
                                      >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                      {errors[day.toLowerCase()] && (
                                        <div className="invalid-feedback">
                                          {errors[day.toLowerCase()]}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {working_hours[0][day.toLowerCase()].open ===
                                    "Yes" && (
                                    <>
                                      <div className="col-lg-4">
                                        <div className="form-group">
                                          <label>From</label>
                                          <input
                                            type="time"
                                            className={`form-control ${
                                              errors[
                                                day.toLowerCase() + "_from"
                                              ]
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            name={`${day.toLowerCase()}.from`}
                                            value={
                                              this.state.working_hours[0][
                                                day.toLowerCase()
                                              ].from
                                            }
                                            onChange={(e) =>
                                              this.handleWorkingHoursChange(
                                                e,
                                                "working_hours",
                                                0
                                              )
                                            }
                                          />
                                          {errors[
                                            day.toLowerCase() + "_from"
                                          ] && (
                                            <div className="invalid-feedback">
                                              {
                                                errors[
                                                  day.toLowerCase() + "_from"
                                                ]
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-4">
                                        <div className="form-group">
                                          <label>To</label>
                                          <input
                                            type="time"
                                            className={`form-control ${
                                              errors[day.toLowerCase() + "_to"]
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            name={`${day.toLowerCase()}.to`}
                                            value={
                                              this.state.working_hours[0][
                                                day.toLowerCase()
                                              ].to
                                            }
                                            onChange={(e) =>
                                              this.handleWorkingHoursChange(
                                                e,
                                                "working_hours",
                                                0
                                              )
                                            }
                                          />
                                          {errors[
                                            day.toLowerCase() + "_to"
                                          ] && (
                                            <div className="invalid-feedback">
                                              {
                                                errors[
                                                  day.toLowerCase() + "_to"
                                                ]
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-12 mb-4">
                            <h5>Experience</h5>
                            <hr />
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="experience">Experience</label>
                                  <input
                                    type="text"
                                    className={`form-control custom-select ${
                                      errors.experience ? "is-invalid" : ""
                                    }`}
                                    id="experience"
                                    placeholder="Experience"
                                    name="experience"
                                    value={user_information[0].experience}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        e,
                                        "user_information",
                                        0
                                      )
                                    }
                                  />
                                  {errors.experience && (
                                    <div className="invalid-feedback">
                                      {errors.experience}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="course">Course</label>
                                  <select
                                    name="course"
                                    className={`form-control custom-select ${
                                      errors.course ? "is-invalid" : ""
                                    }`}
                                    value={user_information[0].course}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        e,
                                        "user_information",
                                        0
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors.course && (
                                    <div className="invalid-feedback">
                                      {errors["user_information"]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {user_information[0].course === "Yes" && (
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label htmlFor="course_name">
                                      Course Name
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        errors.course_name ? "is-invalid" : ""
                                      }`}
                                      id="course_name"
                                      placeholder="Course Name"
                                      name="course_name"
                                      value={user_information[0].course_name}
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          "user_information",
                                          0
                                        )
                                      }
                                    />
                                    {errors.course_name && (
                                      <div className="invalid-feedback">
                                        {errors.course_name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="facebook_url">
                                    Facebook URL
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.facebook_url ? "is-invalid" : ""
                                    }`}
                                    id="facebook_url"
                                    placeholder="Facebook URL"
                                    name="facebook_url"
                                    value={user_information[0].facebook_url}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        e,
                                        "user_information",
                                        0
                                      )
                                    }
                                  />
                                  {errors.facebook_url && (
                                    <div className="invalid-feedback">
                                      {errors.facebook_url}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="instagram_url">
                                    Instagram Url
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.instagram_url ? "is-invalid" : ""
                                    }`}
                                    id="instagram_url"
                                    placeholder="instagram_url"
                                    name="instagram_url"
                                    value={user_information[0].instagram_url}
                                    onChange={(e) =>
                                      this.handleInputChange(
                                        e,
                                        "user_information",
                                        0
                                      )
                                    }
                                  />
                                  {errors.instagram_url && (
                                    <div className="invalid-feedback">
                                      {errors.instagram_url}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12 mb-4">
                                <h5>User Category Assign</h5>
                                <hr />
                                <div className="row">
                                  <div className="col-lg-12 mb-3">
                                    {user_category_assign.map(
                                      (category, index) => (
                                        <div className="row" key={index}>
                                          <div className="col-lg-4">
                                            <div className="form-group">
                                              <label
                                                htmlFor={`category_${index}`}
                                              >
                                                Category
                                              </label>
                                              <select
                                                className={`form-control custom-select ${
                                                  errors[`category_id_${index}`]
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                id={`category_${index}`}
                                                name="category_id"
                                                value={category.category_id}
                                                onChange={(e) =>
                                                  this.handleCategoryChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Select a category
                                                </option>
                                                {this.state.categories.map(
                                                  (cat) => (
                                                    <option
                                                      key={cat._id}
                                                      value={cat._id}
                                                      disabled={user_category_assign.some(
                                                        (c) =>
                                                          c.category_id ===
                                                            cat._id &&
                                                          c !== category
                                                      )}
                                                    >
                                                      {cat.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              {errors[
                                                `category_id_${index}`
                                              ] && (
                                                <div className="invalid-feedback">
                                                  {
                                                    errors[
                                                      `category_id_${index}`
                                                    ]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <div className="form-group">
                                              <label htmlFor={`price_${index}`}>
                                                Price
                                              </label>
                                              <input
                                                type="text"
                                                className={`form-control ${
                                                  errors[`price_${index}`]
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                id={`price_${index}`}
                                                placeholder="Price"
                                                name={`price_${index}`}
                                                value={category.price}
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    e,
                                                    "user_category_assign",
                                                    index,
                                                    "price"
                                                  )
                                                }
                                              />
                                              {errors[`price_${index}`] && (
                                                <div className="invalid-feedback">
                                                  {errors[`price_${index}`]}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <div className="form-group">
                                              <label
                                                htmlFor={`best_services_${index}`}
                                              >
                                                Best Services
                                              </label>
                                              <select
                                                className={`form-control custom-select ${
                                                  errors[
                                                    `best_services_${index}`
                                                  ]
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                id={`best_services_${index}`}
                                                name={`best_services_${index}`}
                                                value={category.best_services}
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    e,
                                                    "user_category_assign",
                                                    index,
                                                    "best_services"
                                                  )
                                                }
                                              >
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                              </select>

                                              {errors[
                                                `best_services_${index}`
                                              ] && (
                                                <div className="invalid-feedback">
                                                  {
                                                    errors[
                                                      `best_services_${index}`
                                                    ]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                this.handleRemoveCategoryAssign(
                                                  index
                                                )
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.handleAddCategoryAssign}
                                >
                                  Add Category Assignment
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(AddExpert);
