import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { storeAdminDetail } from "../../store/actions/admin";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { setLocalStorageItem } from "../../store/localStorage";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      email: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
    };
  }

  validateForm = () => {
    const { email, password } = this.state;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: null });
        }, 5000);
      });
    } else {
      this.setState({ errors: null });

      const { dispatch } = this.props;
      const { email, password } = this.state;
      const { AdminLogin } = adminendPoints;
      const method = "POST";
      const body = {
        email,
        password,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(AdminLogin, method, body, contentType);

        const { status, data } = response;

        if (status === 200) {
          const adminDetails = {
            id: data.adminId,
            email: data.email,
            name: data.name,
            token: data.token,
            role: data.role,
            username: data.username,
          };
          setLocalStorageItem("myStylistAdmin", adminDetails);
          dispatch(storeAdminDetail(adminDetails));

          this.setState(
            {
              successMsg: "Login successful.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 2000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Invalid login details.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call:", error);
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, password, errors, redirect, successMsg, errorMsg } =
      this.state;

    if (redirect) {
      return <Navigate to="/Home" />;
    }

    return (
      <section className="login-page bg-1 align-items-center d-flex min-vh-100">
        <div className="login-box">
          <h3 className="fw-light text-center">Welcome Back!</h3>
          <h2 className="text-center mb-5">Login with your email</h2>

          {errorMsg && (
            <div className="alert alert-danger alert-dismissible">
              {errorMsg}
            </div>
          )}
          {successMsg && (
            <div className="alert alert-success alert-dismissible">
              {successMsg}
            </div>
          )}

          {errors !== null && (
            <>
              <div className="callout callout-danger">
                <p>
                  {errors.email && <div className="error">{errors.email}</div>}
                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </p>
              </div>
            </>
          )}

          <form className="row g-4" onSubmit={this.handleSubmit}>
            <div className="col-lg-12">
              <label htmlFor="" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-lg-12">
              <label htmlFor="" className="form-label">
                Password
              </label>
              <div className="position-relative">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="**************"
                  value={password}
                  onChange={this.handleInputChange}
                />
                <div className="password-icon">
                  <i className="fa-regular fa-eye-slash"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="" />
                <label className="form-check-label" htmlFor="">
                  Stay login
                </label>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <Link to="/forgot-password" className="forgot-password-link">
                Forgot Password?
              </Link>
            </div>
            <div className="col-12">
              <button type="submit" className="btn-login">
                Login
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(Login);
