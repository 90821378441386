import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import moment from "moment";
import Select from "react-select";

class EditForm extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      token: retrievedObject ? retrievedObject.token : "",
      id: "",
      selectedStates: [],
      selectedDistricts: [],
      selectedCities: [],
      selectedServices: [],
      selectedSubServices: [],
      states: [],
      districts: [],
      cities: [],
      services: [],
      subServices: [],
      emailErrorMsg: "",
      isLoading: false,
      error: null,
      number_of_offers: "",
      startDate: "",
      endDate: "",
      additional_information: "",
      featured_image: "",
      selectedFile: null,
      previewURL: null,
      status: "",
      discount: "",
      successMsg: "",
      errorMsg: "",
      errors: {},
      users: [],
    };
  }

  componentDidMount() {
    const offerid = window.location.pathname.split("/").pop();
    this.getOfferidResults(offerid);
    this.getAllUsersResults();
  }

  getOfferidResults = async (id) => {
    try {
      this.setState({ isLoading: true, error: null });

      const { token } = this.state;
      const {
        AdminOfferDetails,
        AdminStateList,
        AdminDistrictList,
        AdminCityList,
        AdminServicesListMobile,
      } = adminendPoints;

      const method = "GET";
      const contentType = "application/json";
      const urls = {
        offerUrl: `${AdminOfferDetails}/${id}`,
        stateUrl: `${AdminStateList}`,
        districtUrl: `${AdminDistrictList}`,
        cityUrl: `${AdminCityList}`,
        serviceUrl: `${AdminServicesListMobile}`,
      };

      const fetchAllData = async () => {
        const data = {};
        await Promise.all(
          Object.keys(urls).map(async (key) => {
            const response = await APICaller(
              urls[key],
              method,
              "",
              contentType,
              token
            );
            data[key] = response.data;
          })
        );
        return data;
      };

      const { offerUrl, stateUrl, districtUrl, cityUrl, serviceUrl } =
        await fetchAllData();

      const { status, offer } = offerUrl;

      if (status === 200) {
        const {
          state,
          district,
          city,
          _id,
          service_name,
          number_of_offers,
          expert_id,
          offer_name,
          start_date,
          end_date,
          additional_information,
          featured_image,
          status,
          discount,
        } = offer;

        const selectedServices = service_name.map((service) => ({
          value: service._id,
          label: service.service_name,
          subServices: (service.sub_services || []).map((subService) => ({
            value: subService._id,
            label: subService.sub_service_name,
          })),
        }));

        const startdt = moment(start_date).format("YYYY-MM-DD");
        const enddt = moment(end_date).format("YYYY-MM-DD");

        this.setState((prevState) => ({
          ...prevState,
          id: _id,
          selectedStates: state.map((s) => ({
            value: s.state_id,
            label: s.state_name,
          })),
          selectedDistricts: district.map((d) => ({
            value: d.district_id,
            label: d.district_name,
          })),
          selectedCities: city.map((c) => ({
            value: c._id,
            label: c.city_name,
          })),
          selectedServices: selectedServices,
          states: stateUrl.states,
          districts: districtUrl.districts,
          cities: cityUrl.citys,
          services: serviceUrl.services,
          subServices: selectedServices.flatMap(
            (service) => service.subServices
          ),
          selectedSubServices: selectedServices.flatMap(
            (service) => service.subServices
          ),
          isLoading: false,
          id: _id,
          expert_id,
          offer_name,
          service_name,
          number_of_offers,
          startDate: startdt,
          endDate: enddt,
          additional_information,
          featured_image,
          status,
          discount,
        }));
      } else {
        this.setState({ error: "Invalid Request" });
        window.setTimeout(() => this.setState({ error: null }), 10000);
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({ isLoading: false, error: "Error fetching data" });
    }
  };

  handleServiceChange = async (selectedOptions) => {
    this.setState({ selectedServices: selectedOptions });

    const selectedServiceIds = selectedOptions.map((service) => service.value);

    try {
      const { token } = this.state;
      const { AdminSubServicesListMobile } = adminendPoints;
      const method = "POST";
      const contentType = "application/json";

      const response = await APICaller(
        AdminSubServicesListMobile,
        method,
        { serviceIds: selectedServiceIds.join(",") },
        contentType,
        token
      );

      const { status, subServices } = response.data;

      if (status === 200) {
        this.setState({
          subServices: (subServices || []).map((subService) => ({
            value: subService._id,
            label: subService.sub_service_name,
          })),
          selectedSubServices: [],
        });
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.log("error fetching sub-services --> ", error);
    }
  };

  validateForm = () => {
    const {
      expert_id,
      offer_name,
      service_name,
      number_of_offers,
      startDate,
      endDate,
      additional_information,
      featured_image,
      status,
      discount,
    } = this.state;

    const newErrors = {};

    if (!expert_id) {
      newErrors.expert_id = "Expert ID is required.";
    }

    if (!offer_name) {
      newErrors.offer_name = "Offer Name is required.";
    }

    if (!service_name || !service_name.length) {
      newErrors.service_name = "At least one service is required.";
    } else {
      service_name.forEach((service, index) => {
        if (!service.service_id) {
          newErrors[`service_name_${index}`] = "Service ID is required.";
        }
        if (!service.service_name) {
          newErrors[`service_name_${index}`] = "Service Name is required.";
        }
        if (!service.sub_services || !service.sub_services.length) {
          newErrors[`sub_services_${index}`] =
            "At least one sub-service is required.";
        } else {
          service.sub_services.forEach((subService, subIndex) => {
            if (!subService.sub_service_id) {
              newErrors[`sub_service_id_${index}_${subIndex}`] =
                "Sub-service ID is required.";
            }
            if (!subService.sub_service_name) {
              newErrors[`sub_service_name_${index}_${subIndex}`] =
                "Sub-service Name is required.";
            }
          });
        }
      });
    }

    if (!number_of_offers) {
      newErrors.number_of_offers = "Number of offers is required.";
    }

    if (!startDate) {
      newErrors.startDate = "Start date is required.";
    }

    if (!endDate) {
      newErrors.endDate = "End date is required.";
    }

    if (!additional_information) {
      newErrors.additional_information = "Additional information is required.";
    }

    if (!featured_image) {
      newErrors.featured_image = "Featured image is required.";
    }

    if (!status) {
      newErrors.status = "Status is required.";
    }

    if (!discount) {
      newErrors.discount = "Discount is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { category_name, selectedFile, token, id, status } = this.state;

      const { AdminEditCategory } = adminendPoints;
      const formData = new FormData();
      formData.append("id", id);
      formData.append("category_name", category_name);
      formData.append("status", status);
      formData.append("fileName", selectedFile);

      const method = "POST";
      const body = formData;

      try {
        const response = await APICaller(
          AdminEditCategory,
          method,
          body,
          {},
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Category updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This category name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create category. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        selectedFile: file,
        previewURL: reader.result,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  handleFileRemove = () => {
    document.getElementById("fileName").value = "";

    this.setState({
      selectedFile: null,
      previewURL: null,
      fileName: "",
    });
  };

  getAllUsersResults = async () => {
    const { token } = this.state;
    const { AdminGetAllUsers } = adminendPoints;
    const method = "POST"; // Change the method based on your API
    const contentType = "application/json";
    const url = `${AdminGetAllUsers}`;

    try {
      const response = await APICaller(url, method, contentType, token);
      const { status, users } = response.data;

      if (status === 200) {
        this.setState({
          users: users,
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  render() {
    const {
      selectedStates,
      selectedDistricts,
      selectedCities,
      states,
      districts,
      cities,
      expert_id,
      offer_name,
      service_name,
      number_of_offers,
      startDate,
      endDate,
      additional_information,
      featured_image,
      status,
      discount,
      redirect,
      successMsg,
      errorMsg,
      token,
      errors,
      previewURL,
      users,
      services,
      selectedServices,
      subServices,
    } = this.state;
    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageOffers" />;
    }

    const serviceOptions = services.map((service) => ({
      value: service._id,
      label: service.service_name,
    }));

    const subServiceOptions = subServices.map((subService) => ({
      value: subService.value,
      label: subService.label,
    }));

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />

          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="bs-r-6 ps-3">
                  <h4 className="card-title mb-0">Edit Offer Information</h4>
                  <p className="mb-0 tc-5">1349 User Added</p>
                </div>
                <div className="ms-auto">
                  <Link to="/ManageOffers" className="link">
                    <img
                      className="me-2"
                      src="assets/img/icon-edit1.svg"
                      alt=""
                      title=""
                    />
                    <span>Back</span>
                  </Link>
                </div>
              </div>
              <form className="form-area1 row g-4" onSubmit={this.handleSubmit}>
                {errorMsg && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alert!
                    </h5>
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="alert alert-success alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5>
                      <i className="icon fas fa-check"></i> Alert!
                    </h5>
                    {successMsg}
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Service</label>
                    <Select
                      id="service"
                      options={serviceOptions}
                      value={selectedServices}
                      isMulti
                      onChange={this.handleServiceChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="service">Sub Service</label>
                  <Select
                    id="subServices"
                    options={subServiceOptions}
                    isMulti
                    value={this.state.selectedSubServices}
                    onChange={(selectedOptions) =>
                      this.setState({ selectedSubServices: selectedOptions })
                    }
                  />
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select Sub Service</label>
                    <Select
                      value={selectedStates}
                      onChange={(selectedOptions) =>
                        this.setState({
                          selectedStates: selectedOptions,
                          selectedDistricts: [],
                          selectedCities: [],
                        })
                      }
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select State</label>
                    <Select
                      value={selectedStates}
                      onChange={(selectedOptions) =>
                        this.setState({
                          selectedStates: selectedOptions,
                          selectedDistricts: [],
                          selectedCities: [],
                        })
                      }
                      options={states.map((state) => ({
                        value: state._id,
                        label: state.state_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select District</label>
                    <Select
                      value={selectedDistricts}
                      onChange={(selectedOptions) => {
                        this.setState({
                          selectedDistricts: selectedOptions,
                          selectedCities: [],
                        });
                      }}
                      options={districts.map((district) => ({
                        value: district._id,
                        label: district.district_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Select City</label>
                    <Select
                      value={selectedCities}
                      onChange={(selectedOptions) =>
                        this.setState({ selectedCities: selectedOptions })
                      }
                      options={(cities || []).map((city) => ({
                        value: city._id,
                        label: city.city_name,
                      }))}
                      isMulti
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <label htmlFor="expert_id" className="form-label">
                    Expert Name
                  </label>
                  <select
                    id="expert_id"
                    name="expert_id"
                    className="form-select"
                    value={expert_id}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select an expert</option>
                    {users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                  {errors.expert_id && (
                    <p className="error">{errors.expert_id}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="offer_name">Offer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="offer_name"
                    placeholder="Offer Name"
                    name="offer_name"
                    value={offer_name}
                    onChange={this.handleInputChange}
                  />
                  {errors.offer_name && (
                    <p className="error">{errors.offer_name}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    value={startDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="startDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    value={endDate}
                    onChange={this.handleInputChange}
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="number_of_offers"> Number of Offers </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_offers"
                    placeholder="Number of Offers"
                    name="number_of_offers"
                    value={number_of_offers}
                    onChange={this.handleInputChange}
                  />
                  {errors.number_of_offers && (
                    <p className="error">{errors.number_of_offers}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount"
                    placeholder="Discount"
                    name="discount"
                    value={discount}
                    onChange={this.handleInputChange}
                  />
                  {errors.discount && (
                    <p className="error">{errors.discount}</p>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="additional_information">
                      Additional Information
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_information"
                      placeholder="Additional Information"
                      name="additional_information"
                      value={additional_information}
                      onChange={this.handleInputChange}
                    />
                    {errors.additional_information && (
                      <p className="error">{errors.additional_information}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>

                    <select
                      name="status"
                      className="form-select"
                      id="status"
                      onChange={this.handleInputChange}
                      aria-label="Default select example"
                    >
                      <option value="">Select Status</option>
                      <option value="Active" selected={status === "Active"}>
                        Active
                      </option>
                      <option
                        value="In-Active"
                        selected={status === "In-Active"}
                      >
                        In-Active
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col">
                  <div className="upload-img-box">
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <p className="mb-0">Upload Image</p>
                    <input
                      type="file"
                      className="form-control"
                      id="fileName"
                      placeholder="Absence through"
                      name="fileName"
                      accept="image/*"
                      onChange={this.handleFileSelect}
                    />
                    {previewURL && (
                      <div>
                        <img
                          src={previewURL}
                          alt="Category Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                        <button type="button" onClick={this.handleFileRemove}>
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn-blue1">
                    Update Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditForm;
