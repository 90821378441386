import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";
import SideBar from "../SideBar/index";

class AmenitiesAndOthers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      token: getLocalStorageItem("myStylistAdmin")?.token || "",
      addresses: [
        {
          pinCode: "",
          landmark: "",
          location: { type: "Point", coordinates: [] },
        },
      ],
      aawl: [{ toilet: "", hygiene: "", drinking_water: "", ac: "" }],
      amenities: [
        {
          parking_space: "",
          music: "",
          credit_cards_accepted: "",
          wi_fi: "",
          pets_friendly: "",
          child_friendly: "",
          selfie_station: "",
        },
      ],
      successMsg: "",
      errorMsg: "",
      errors: "",
      redirect: false,
      loading: false,
    };
  }

  componentDidMount() {
    const userid = window.location.pathname.split("/").pop();
    this.getUserResults(userid);
  }

  getUserResults = async (userid) => {
    try {
      const { token } = this.state;
      const { AdminExpertUserDetails } = adminendPoints;
      const expertUrl = `${AdminExpertUserDetails}`;
      const contentType = "application/json";
      const body = {
        userid,
      };

      const response = await APICaller(
        expertUrl,
        "POST",
        body,
        contentType,
        token
      );
      const { status, user } = response.data;

      if (user) {
        const updatedAddress = user.address.map((item) => {
          const locationCoordinates =
            (item.location && item.location.coordinates) || [];

          return {
            pinCode: item.pinCode,
            landmark: item.landmark,
            location: {
              type: "Point",
              coordinates: locationCoordinates,
            },
          };
        });

        const aawl = user.aawl && user.aawl[0];
        const amenities = user.amenities && user.amenities[0];

        this.setState({
          id: user._id,
          addresses: updatedAddress,
          aawl: [
            {
              toilet: aawl ? aawl.toilet : "",
              hygiene: aawl ? aawl.hygiene : "",
              drinking_water: aawl ? aawl.drinking_water : "",
              ac: aawl ? aawl.ac : "",
            },
          ],
          amenities: [
            {
              parking_space: amenities ? amenities.parking_space : "",
              music: amenities ? amenities.music : "",
              credit_cards_accepted: amenities
                ? amenities.credit_cards_accepted
                : "",
              wi_fi: amenities ? amenities.wi_fi : "",
              pets_friendly: amenities ? amenities.pets_friendly : "",
              child_friendly: amenities ? amenities.child_friendly : "",
              selfie_station: amenities ? amenities.selfie_station : "",
            },
          ],
        });
      } else {
        this.setState({
          errorMsg: "Failed to create City. Please try again.",
          successMsg: "",
        });
      }
    } catch (error) {
      console.log("error making data call --> ", error);
    }
  };

  handleAddressInputChange = (e, index) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedAddresses = [...prevState.addresses];
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        [name]: value,
      };
      return { addresses: updatedAddresses };
    });
  };

  handleCoordinatesChange = (e, index) => {
    const { name, value } = e.target;
    const coordinates = value.split(",").map(Number);

    this.setState((prevState) => {
      const updatedAddresses = prevState.addresses.map((item, i) =>
        i === index
          ? { ...item, location: { ...item.location, [name]: coordinates } }
          : item
      );

      return { addresses: updatedAddresses };
    });
  };

  handleDropdownChange = (e, group, index, field) => {
    const { name, value } = e.target;

    if (group !== undefined && index !== undefined && field) {
      this.setState((prevState) => {
        const updatedGroup = [...prevState[group]];
        updatedGroup[index] = {
          ...updatedGroup[index],
          [field]: value,
        };

        return { [group]: updatedGroup };
      });
    }
  };
  handleAawlChange = (e, field) => {
    const { value } = e.target;

    this.setState((prevState) => {
      const updatedAawl = [{ ...prevState.aawl[0], [field]: value }];
      return { aawl: updatedAawl };
    });
  };

  handleAmenitiesChange = (e, field) => {
    const { value } = e.target;

    this.setState((prevState) => {
      const updatedAmenities = [{ ...prevState.amenities[0], [field]: value }];
      return { amenities: updatedAmenities };
    });
  };

  validateForm = () => {
    const { aawl, amenities, addresses } = this.state;

    const errors = {};

    const addressErrors = {};
    if (!addresses[0].pinCode) {
      addressErrors.pinCode = "Pin Code is required";
    }
    if (!addresses[0].landmark) {
      addressErrors.landmark = "Landmark is required";
    }
    if (
      !addresses[0].location ||
      !addresses[0].location.coordinates ||
      addresses[0].location.coordinates.length !== 2 ||
      isNaN(addresses[0].location.coordinates[0]) ||
      isNaN(addresses[0].location.coordinates[1])
    ) {
      addressErrors.coordinates = "Invalid Coordinates";
    }

    Object.assign(errors, addressErrors);

    const aawlErrors = {};
    if (!aawl[0].toilet) {
      aawlErrors.toilet = "Toilet field is required";
    }
    if (!aawl[0].hygiene) {
      aawlErrors.hygiene = "Hygiene field is required";
    }

    if (!aawl[0].drinking_water) {
      aawlErrors.drinking_water = "Drinking water field is required";
    }

    if (!aawl[0].ac) {
      aawlErrors.ac = "AC field is required";
    }

    Object.assign(errors, aawlErrors);

    const amenitiesErrors = {};
    if (!amenities[0].parking_space) {
      amenitiesErrors.parking_space = "Parking Space field is required";
    }
    if (!amenities[0].music) {
      amenitiesErrors.music = "Music field is required";
    }

    if (!amenities[0].credit_cards_accepted) {
      amenitiesErrors.credit_cards_accepted =
        "Credit cards accepted field is required";
    }

    if (!amenities[0].wi_fi) {
      amenitiesErrors.wi_fi = "wi fi field is required";
    }

    if (!amenities[0].pets_friendly) {
      amenitiesErrors.pets_friendly = "Pets friendly field is required";
    }

    if (!amenities[0].child_friendly) {
      amenitiesErrors.child_friendly = "Child friendly field is required";
    }

    if (!amenities[0].selfie_station) {
      amenitiesErrors.selfie_station = "Selfie station field is required";
    }

    Object.assign(errors, amenitiesErrors);

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });

      const { addresses, aawl, amenities, id, token } = this.state;

      const body = {
        id,
        addresses,
        aawl,
        amenities,
      };

      const { AdminUpdateAmenities } = adminendPoints;
      const method = "POST";

      const contentType = "application/json";
      try {
        const response = await APICaller(
          AdminUpdateAmenities,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsg: "Amenities And Others Updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
              }, 1000);
            }
          );
        } else if (status === 201) {
          this.setState(
            {
              errorMsg: "This District name is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create State. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the District. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  render() {
    const {
      id,
      errors,
      addresses,
      aawl,
      amenities,
      redirect,
      successMsg,
      errorMsg,
      token,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ManageExperts" />;
    }

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">
                      Amenities And Others Information
                    </h4>
                  </div>
                  <div className="ms-auto">
                    <Link to={`/EditExpert/${id}`} className="btn-light1 ms-2">
                      <i className="fa-solid fa-arrow-left-long me-2"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="">
                    {errorMsg && (
                      <div className="alert alert-danger" role="alert">
                        {errorMsg}
                      </div>
                    )}
                    {successMsg && (
                      <div className="alert alert-success" role="alert">
                        {successMsg}
                      </div>
                    )}

                    {/* {loading && (
                      <div className="d-flex justify-content-center align-items-center loader">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )} */}
                    <div className="row">
                      <div className="col-lg-12">
                        <h5>Amenities And Others Information</h5>
                        <hr />
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor={`pinCode`}>Pin Code</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors[`pinCode`] ? "is-invalid" : ""
                                }`}
                                id={`pinCode`}
                                placeholder="Pin Code"
                                name="pinCode"
                                value={addresses[0]?.pinCode || ""}
                                onChange={(e) =>
                                  this.handleAddressInputChange(e, 0)
                                }
                              />
                              {errors[`pinCode`] && (
                                <div className="invalid-feedback">
                                  {errors[`pinCode`]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor={`landmark`}>Landmark</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors[`landmark`] ? "is-invalid" : ""
                                }`}
                                id={`landmark`}
                                placeholder="Landmark"
                                name="landmark"
                                value={addresses[0]?.landmark || ""}
                                onChange={(e) =>
                                  this.handleAddressInputChange(e, 0)
                                }
                              />
                              {errors[`landmark`] && (
                                <div className="invalid-feedback">
                                  {errors[`landmark`]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor={`coordinates`}>
                                Coordinates (latitude, longitude)
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors[`coordinates`] ? "is-invalid" : ""
                                }`}
                                id={`coordinates`}
                                placeholder="Coordinates"
                                name="coordinates"
                                value={
                                  addresses[0]?.location?.coordinates?.join(
                                    ", "
                                  ) || ""
                                }
                                onChange={(e) =>
                                  this.handleCoordinatesChange(e, 0)
                                }
                              />
                              {errors[`coordinates`] && (
                                <div className="invalid-feedback">
                                  {errors[`coordinates`]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor="toilet">Toilet</label>
                              <select
                                name="toilet"
                                className={`form-control custom-select ${
                                  errors["toilet"] ? "is-invalid" : ""
                                }`}
                                value={aawl[0].toilet}
                                onChange={(e) =>
                                  this.handleAawlChange(e, "toilet")
                                }
                              >
                                <option value="">Select Value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {errors["toilet"] && (
                                <div className="invalid-feedback">
                                  {errors["toilet"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor="hygiene">Hygiene</label>
                              <select
                                name="hygiene"
                                className={`form-control custom-select ${
                                  errors["hygiene"] ? "is-invalid" : ""
                                }`}
                                value={aawl[0].hygiene}
                                onChange={(e) =>
                                  this.handleAawlChange(e, "hygiene")
                                }
                              >
                                <option value="">Select Value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {errors["hygiene"] && (
                                <div className="invalid-feedback">
                                  {errors["hygiene"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor="drinking_water">
                                Drinking Water
                              </label>
                              <select
                                name="drinking_water"
                                className={`form-control custom-select ${
                                  errors["drinking_water"] ? "is-invalid" : ""
                                }`}
                                value={aawl[0].drinking_water}
                                onChange={(e) =>
                                  this.handleAawlChange(e, "drinking_water")
                                }
                              >
                                <option value="">Select Value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {errors["drinking_water"] && (
                                <div className="invalid-feedback">
                                  {errors["drinking_water"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label htmlFor="ac">Air Condition</label>
                              <select
                                name="ac"
                                className={`form-control custom-select ${
                                  errors["ac"] ? "is-invalid" : ""
                                }`}
                                value={aawl[0].ac}
                                onChange={(e) => this.handleAawlChange(e, "ac")}
                              >
                                <option value="">Select Value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {errors["ac"] && (
                                <div className="invalid-feedback">
                                  {errors["ac"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-4">
                            <h5>Amenities</h5>
                            <hr />
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="parking_space">
                                    Parking Space
                                  </label>
                                  <select
                                    name="parking_space"
                                    className={`form-control custom-select ${
                                      errors["parking_space"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={amenities[0].parking_space}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(
                                        e,
                                        "parking_space"
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["parking_space"] && (
                                    <div className="invalid-feedback">
                                      {errors["parking_space"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="music">Music</label>
                                  <select
                                    name="music"
                                    className={`form-control custom-select ${
                                      errors["music"] ? "is-invalid" : ""
                                    }`}
                                    value={amenities[0].music}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(e, "music")
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["music"] && (
                                    <div className="invalid-feedback">
                                      {errors["music"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="credit_cards_accepted">
                                    Credit Cards Accepted
                                  </label>
                                  <select
                                    name="credit_cards_accepted"
                                    className={`form-control custom-select ${
                                      errors["credit_cards_accepted"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={amenities[0].credit_cards_accepted}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(
                                        e,
                                        "credit_cards_accepted"
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["credit_cards_accepted"] && (
                                    <div className="invalid-feedback">
                                      {errors["credit_cards_accepted"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="wi_fi">Wi-Fi</label>
                                  <select
                                    name="wi_fi"
                                    className={`form-control custom-select ${
                                      errors["wi_fi"] ? "is-invalid" : ""
                                    }`}
                                    value={amenities[0].wi_fi}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(e, "wi_fi")
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["wi_fi"] && (
                                    <div className="invalid-feedback">
                                      {errors["wi_fi"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="pets_friendly">
                                    Pets Friendly
                                  </label>
                                  <select
                                    name="pets_friendly"
                                    className={`form-control custom-select ${
                                      errors["pets_friendly"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={amenities[0].pets_friendly}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(
                                        e,
                                        "pets_friendly"
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["pets_friendly"] && (
                                    <div className="invalid-feedback">
                                      {errors["pets_friendly"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="child_friendly">
                                    Child Friendly
                                  </label>
                                  <select
                                    name="child_friendly"
                                    className={`form-control custom-select ${
                                      errors["child_friendly"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={amenities[0].child_friendly}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(
                                        e,
                                        "child_friendly"
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["child_friendly"] && (
                                    <div className="invalid-feedback">
                                      {errors["child_friendly"]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label htmlFor="selfie_station">
                                    Selfie Station
                                  </label>
                                  <select
                                    name="selfie_station"
                                    className={`form-control custom-select ${
                                      errors["selfie_station"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={amenities[0].selfie_station}
                                    onChange={(e) =>
                                      this.handleAmenitiesChange(
                                        e,
                                        "selfie_station"
                                      )
                                    }
                                  >
                                    <option value="">Select Value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  {errors["selfie_station"] && (
                                    <div className="invalid-feedback">
                                      {errors["selfie_station"]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AmenitiesAndOthers);
