import React, { Component } from "react";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

class AddFaq extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      FaqTitle: "",
      FaqAnswer: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  validateForm = () => {
    const { FaqTitle, FaqAnswer } = this.state;
    const newErrors = {};

    if (!FaqTitle) {
      newErrors.FaqTitle = "Faq Title is required.";
    }

    if (!FaqAnswer) {
      newErrors.FaqAnswer = "Faq Answer is required.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        window.setTimeout(() => {
          this.setState({ errors: {} });
        }, 5000);
      });
    } else {
      this.setState({ errors: {} });
      const { FaqTitle, FaqAnswer, token } = this.state;

      const { AdminAddFaq } = adminendPoints;

      const method = "POST";
      const body = {
        FaqTitle,
        FaqAnswer,
      };
      const contentType = "application/json";
      try {
        const response = await APICaller(
          AdminAddFaq,
          method,
          body,
          contentType,
          token
        );

        const { status } = response.data;

        if (status === 201) {
          this.setState(
            {
              successMsg: "Faq created successfully.",
              errorMsg: "",
              FaqTitle: "",  // Reset FaqTitle
              FaqAnswer: "", // Reset FaqAnswer
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
                this.props.refreshList(); 
              }, 5000);
            }
          );
        } else if (status === 204) {
          this.setState(
            {
              errorMsg: "This Faq is already exist",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to create Faq. Please try again.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("error making data call --> ", error);
        this.setState({
          errorMsg:
            "An error occurred while creating the category. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { FaqTitle, FaqAnswer, errors, successMsg, errorMsg } = this.state;
    const { isVisibleAdd, handleCloseAddContent } = this.props;

    return (
      <div>
        <div
          className={`right-side-popup right-side-popup-w75 ${
            isVisibleAdd ? "" : "right-side-popup-hide"
          }`}
        >
          <div
            className="right-side-popup-close"
            onClick={handleCloseAddContent}
          >
            <i className="fa-solid fa-angles-right"></i>
          </div>
          <div className="right-side-popup-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Add Faq Information</h4>
                    <p className="mb-0 tc-5">Add Faq</p>
                  </div>
                  <div className="ms-auto"> </div>
                </div>

                <form
                  className="form-area row align-items-center mb-5"
                  onSubmit={this.handleSubmit}
                >
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                      <h5>
                        <i className="icon fas fa-ban"></i> Alert!
                      </h5>
                      {errorMsg}
                    </div>
                  )}
                  {successMsg && (
                    <div className="alert alert-success alert-dismissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        ×
                      </button>
                      <h5>
                        <i className="icon fas fa-check"></i> Alert!
                      </h5>
                      {successMsg}
                    </div>
                  )}

                  {errors && (
                    <div className="callout callout-danger">
                      <h5>All * fields are required</h5>
                      {errors.FaqTitle && (
                        <p className="error">{errors.FaqTitle}</p>
                      )}
                      {errors.FaqAnswer && (
                        <p className="error">{errors.FaqAnswer}</p>
                      )}
                    </div>
                  )}

                  <div className="form-area1 row g-4">
                    <div className="col-lg-6">
                      <label htmlFor="FaqTitle" className="form-label">
                        Faq Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="FaqTitle"
                        placeholder="Faq Name"
                        name="FaqTitle"
                        value={FaqTitle}
                        onChange={this.handleInputChange}
                      />
                      {errors.FaqTitle && (
                        <p className="error">{errors.FaqTitle}</p>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label htmlFor="FaqAnswer" className="form-label">
                        Faq Answer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="FaqAnswer"
                        placeholder="Faq Answer"
                        name="FaqAnswer"
                        value={FaqAnswer}
                        onChange={this.handleInputChange}
                      />
                      {errors.FaqAnswer && (
                        <p className="error">{errors.FaqAnswer}</p>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn-blue">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`right-side-popup-backdrop ${
            isVisibleAdd ? "" : "d-none"
          }`}
        ></div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});
export default connect(mapStatetoProps)(AddFaq);
