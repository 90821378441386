import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";

export default class UserJobs extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    this.state = {
      showConfirmation: false,
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      activeTab: "All",
      search: "",
      sort: "",
      isVisibleView: false,
      isVisibleAdd: false,
      counts: "",
      startDate: "",
      endDate: "",
      sort: "",
      jobDetailsresults: "",
      featured_image_url: "https://mystylist-media.s3.amazonaws.com",
    };
  }

  componentDidMount() {
    this.getAlljobs();
    this.getJobsCount();
  }

  getJobsCount = async () => {
    const { token } = this.state;
    const { AdminGetAllJobsCountByStatus } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = {};
      const response = await APICaller(
        AdminGetAllJobsCountByStatus,
        method,
        body,
        contentType,
        token
      );

      const { status, counts } = response.data;

      if (status === 200) {
        this.setState({
          counts: counts,
        });
      } else {
        // Handle error for the API request here if needed
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching users by category. Please try again.",
        successMsg: "",
      });
    }
  };

  getAlljobs = () => {
    const { token, currentPage, itemsPerPage, activeTab } = this.state;

    const { AdminGetAllJobs } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    let queryParams = `page=${currentPage}&limit=${itemsPerPage}`;

    // Add 'activeTab' to query parameters if it exists
    if (activeTab != "All") {
      queryParams += `&jobStatus=${activeTab}`;
    }

    const url = `${AdminGetAllJobs}${queryParams ? `?${queryParams}` : ""}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, jobs, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: jobs,
            totalItems: totalItems,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error making data call:", error);
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getAlljobs(); // Fix: Change this to getAlljobs
    });
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1 }, () => {
        this.getAlljobs();
      });
    }
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleCloseViewContent = () => {
    this.setState({ isVisibleView: false });
  };

  viewCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  handleCloseAddContent = () => {
    this.setState({ isVisibleAdd: false });
  };

  addCategorytoggle = () => {
    this.setState((prevState) => ({
      isVisibleAdd: !prevState.isVisibleAdd,
    }));
  };

  handleToggleCheckBox = async (jobId, currentStatus) => {
    const { token } = this.state;
    const { AdminSalonStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus =
      currentStatus === "In-Active" ? "In-Progress" : "In-Active";

    const body = {
      jobId,
      jobStatus: newStatus,
    };

    try {
      const response = await APICaller(
        AdminSalonStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((job) =>
            job._id === jobId ? { ...job, status: newStatus } : job
          ),
          successMsg: `Job Status is ${newStatus}`,
          errorMsg: "",
        }));
        this.getAlljobs();
        this.getJobsCount();
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
      } else {
        console.error("Failed to update Job status");
        this.setState({
          errorMsg: message,
          successMsg: "", // Clear any previous success messages
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "", // Clear any previous success messages
      });
    }
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value }, () => {});
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value }, () => {});
  };

  handleSortChange = (e) => {
    this.setState({ sort: e.target.value });
  };

  viewCategorytoggle = (jobId) => {
    this.getJobrDetails(jobId);
    this.setState((prevState) => ({
      isVisibleView: !prevState.isVisibleView,
    }));
  };

  getJobrDetails = (id) => {
    const { token } = this.state;
    const { AdminJobDetails } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminJobDetails}/${id}`;

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, offer } = response.data;

        if (status === 200) {
          this.setState({
            jobDetailsresults: offer,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
      });
  };

  openImageModal = (imageUrl) => {
    this.setState({
      isImageModalOpen: true,
      selectedImageUrl: imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      selectedImageUrl: "",
    });
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      sort,
      activeTab,
      isVisibleView,
      counts,
      startDate,
      endDate,
      jobDetailsresults,
      featured_image_url,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredAndSortedItems = Array.isArray(results)
      ? [...results]
          .filter((user) => {
            const matchesSearch = user.jobDescription
              .toLowerCase()
              .includes(search.toLowerCase());

            const createdAtTimestamp = new Date(
              user.createdAt.$date || user.createdAt
            ).getTime();
            const startTimestamp = startDate
              ? new Date(startDate).getTime()
              : null;
            const endTimestamp = endDate ? new Date(endDate).getTime() : null;

            const isWithinDateRange =
              (!startTimestamp || createdAtTimestamp >= startTimestamp) &&
              (!endTimestamp || createdAtTimestamp <= endTimestamp);
            return matchesSearch && isWithinDateRange;
          })
          .sort((a, b) => {
            return sort === "asc"
              ? a._id.localeCompare(b._id)
              : b._id.localeCompare(a._id);
          })
      : [];

    const currentItems = filteredAndSortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    console.log(jobDetailsresults, "jobDetailsresults");

    const totalPages = Math.ceil(filteredAndSortedItems.length / itemsPerPage);

    const countForApproval = counts.inactive;
    const countInProgress = counts.inprogress;
    const countAccepted = counts.accepted;
    const countCompleted = counts.completed;

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">User Jobs</li>
                    </ol>
                    <h4 className="card-title mb-0">User Jobs</h4>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Active")}
                    className={activeTab === "In-Active" ? "active" : ""}
                  >
                    For-Approval ({countForApproval})
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Progress")}
                    className={activeTab === "In-Progress" ? "active" : ""}
                  >
                    In-Progress ({countInProgress})
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Accepted")}
                    className={activeTab === "Accepted" ? "active" : ""}
                  >
                    Accepted ({countAccepted})
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Completed")}
                    className={activeTab === "Completed" ? "active" : ""}
                  >
                    Completed ({countCompleted})
                  </Link>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-lg-4">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <input
                          type="date"
                          className="form-control"
                          name="startDate"
                          onChange={this.handleStartDateChange}
                          value={startDate}
                        />
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          onChange={this.handleEndDateChange}
                          value={endDate}
                        />
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          onChange={this.handleSortChange}
                          value={sort}
                        >
                          <option value="All">All</option>
                          <option value="asc">Asc</option>
                          <option value="desc">Desc</option>
                        </select>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {filteredAndSortedItems.map((item) => (
                    <div className="col-lg-3">
                      <div className="job-box">
                        <div className="row g-2 justify-content-between mb-2">
                          <div className="col-auto">
                            <Link
                              to="#"
                              className="icon-btn"
                              onClick={() =>
                                this.openImageModal(
                                  featured_image_url + "/" + item.featured_image
                                )
                              }
                            >
                              <figure className="mb-0 user-img user-img-40">
                                <img
                                  className="w-100"
                                  src={`${item.featured_image_url}/${item.featured_image}`}
                                  alt=""
                                  title=""
                                />
                              </figure>
                            </Link>
                          </div>

                          <div className="col-auto text-end">
                            {item.jobStatus &&
                              (item.jobStatus === "In-Active" ? (
                                <button
                                  type="button"
                                  className="btn-red-light fs-13"
                                  onClick={() =>
                                    this.handleToggleCheckBox(
                                      item._id,
                                      item.jobStatus
                                    )
                                  }
                                >
                                  {item.jobStatus}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn-light fs-13"
                                >
                                  {item.jobStatus}
                                </button>
                              ))}

                            <button
                              type="button"
                              className="btn-light-round ms-2"
                              onClick={() => this.viewCategorytoggle(item._id)}
                            >
                              <i class="fa-regular fa-eye"></i>
                            </button>
                          </div>
                        </div>
                        <p className="fs-12 fw-medium tc-5 mb-1">
                          {item.user_details
                            ? item.user_details.username
                            : "N/A"}
                        </p>

                        <p className="fw-semibold tc-6 mb-1">
                          {item?.jobDescription}
                        </p>
                        <p className="fs-16 tc-5 mb-2">
                          <i className="fa-regular fa-clock me-2 align-middle"></i>
                          <span className="fs-12 align-middle">
                            {formatDate(item.appointment_date)}
                          </span>
                          <span className="fs-12 mx-2 align-middle">-</span>
                          <span className="fs-12 align-middle">
                            {item.fromtime}
                          </span>
                        </p>

                        <div className="row justify-content-between mb-2">
                          <div className="col">
                            <p className="fs-12 tc-5 mb-1">Budget</p>
                            <p className="fs-14 tc-6 fw-semibold mb-1">
                              ₹{item.budget}
                            </p>
                          </div>
                          <div className="col">
                            <p className="fs-12 tc-5 mb-1">Max Distance</p>
                            <p className="fs-14 tc-6 fw-semibold mb-1">
                              {item.maxdistance}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {this.state.isImageModalOpen && (
                    <div
                      className="modal"
                      tabIndex="-1"
                      role="dialog"
                      style={{ display: "block" }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            <span
                              className="close"
                              onClick={this.closeImageModal}
                            >
                              &times;
                            </span>
                            <img
                              src={this.state.selectedImageUrl}
                              alt="View Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}

                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isVisibleView && (
          <div>
            <div
              className={`right-side-popup ${
                isVisibleView ? "" : "right-side-popup-hide"
              }`}
            >
              <div
                className="right-side-popup-close"
                onClick={this.handleCloseViewContent}
              >
                <i className="fa-solid fa-angles-right"></i>
              </div>
              <div className="right-side-popup-inner">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="bs-r-6 ps-3">
                      <h4 className="card-title mb-0">
                        {jobDetailsresults.jobTitle}
                      </h4>
                      <p className="mb-0 tc-5">
                        Last Updated: {formatDate(jobDetailsresults.updatedAt)}
                      </p>
                    </div>
                    <div className="ms-auto">
                      <div className="ms-3">
                        <button
                          type="button"
                          className="btn-blue red-btn-color"
                        >
                          <i className="fa-regular fa-trash-can me-2"></i>
                          {jobDetailsresults.jobStatus}
                        </button>
                      </div>
                    </div>
                  </div>

                  <h5 className="mb-3 tc-8">Job Details</h5>
                  <h5 className="mb-2 tc-6">{jobDetailsresults.jobTitle}</h5>
                  <p className="mb-2 tc-5">
                    Start Date: {formatDate(jobDetailsresults.start_date)}
                  </p>
                  <p className="mb-4 tc-5">
                    End Date: {formatDate(jobDetailsresults.end_date)}
                  </p>

                  <p className="mb-4 tc-5">
                    Budget: {jobDetailsresults.budget}
                  </p>

                  <p className="mb-4 tc-5">
                    MinDistance: {jobDetailsresults.mindistance}
                  </p>

                  <p className="mb-4 tc-5">
                    MaxDistance: {jobDetailsresults.maxdistance}
                  </p>

                  <h5 className="mb-2 tc-8">Selected Services</h5>

                  <div className="d-flex mb-3">
                    {jobDetailsresults.service_name?.map((service, index) => (
                      <div key={index} className="btn-tag me-2 mb-2">
                        {service.service_name}
                      </div>
                    ))}
                  </div>

                  <h5 className="mb-2 tc-8">Desired Product Used</h5>

                  <div className="d-flex mb-3">
                    {jobDetailsresults.desiredProductUsed?.map(
                      (products, index) => (
                        <div key={index} className="btn-tag me-2 mb-2">
                          {products}
                        </div>
                      )
                    )}
                  </div>

                  <h4 className="mb-3">Stylist Detail</h4>
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-8">
                      <div className="d-flex align-items-center">
                        <figure className="flex-shrink-0 me-3 mb-0 user-img-40">
                          <img
                            src="assets/img/dummy-user.png"
                            alt=""
                            title=""
                          />
                        </figure>
                        <div className="">
                          <p className="mb-0">
                            <strong>
                              {jobDetailsresults.user_details?.name}
                            </strong>
                          </p>
                          <p className="fs-12 mb-0">
                            @{jobDetailsresults.user_details?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 text-end">
                      <button type="button" className="btn-light-round">
                        <i className="fa-regular fa-message"></i>
                      </button>
                    </div>
                    <div className="col-lg-12">
                      <p className="h5 fw-normal mt-2">
                        {jobDetailsresults.jobDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`right-side-popup-backdrop ${
                isVisibleView ? "" : "d-none"
              }`}
            ></div>
          </div>
        )}
      </div>
    );
  }
}
