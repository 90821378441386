import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";


export default class Home extends Component {

  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");

    this.state = {
      counts:"",
      totalCount:""
    };
  }

  componentDidMount() {
    this.getJobsCount();
  }

  getJobsCount = async () => {
    const { token } = this.state;
    const { AdminGetAllJobsCountByStatus } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = {};
      const response = await APICaller(
        AdminGetAllJobsCountByStatus,
        method,
        body,
        contentType,
        token
      );

      const { status, counts,totalCount } = response.data;

      if (status === 200) {
        this.setState({
          counts: counts,
          totalCount:totalCount
        });
      } else {
        // Handle error for the API request here if needed
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching users by category. Please try again.",
        successMsg: "",
      });
    }
  };


  render() {

    const {
      counts,
      totalCount
    } = this.state;

    const countForApproval = counts.inactive;
    const countInProgress = counts.inprogress;
    const countAccepted = counts.accepted;
    const countCompleted = counts.completed;
    
   


    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h4 className="card-title mb-0">Dashboard</h4>
                  <div className="ms-auto">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-calendar.svg"
                        alt=""
                        title=""
                      />
                      January
                    </button>
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                        title=""
                      />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Number Of Job Posted ({totalCount})</p>
                    </div>
                    <h3> For-Approval ({countForApproval})</h3>
                    <h3> In-Progress ({countInProgress})</h3>
                    <h3> Accepted ({countAccepted})</h3>
                    <h3> Completed ({countCompleted})</h3>
                  
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-3 tc-3 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-4 tc-4 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-3 tc-3 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-3 tc-3 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-4 tc-4 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-4 tc-4 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div className="me-3">
                        <div className="icon-wrap">
                          <img
                            src="assets/img/icon-phone.svg"
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                      <p className="mb-0">Total Stylist Apps Download</p>
                    </div>
                    <h3>$23,0945</h3>
                    <div className="d-flex align-items-center fs-12">
                      <span className="badge bg-3 tc-3 me-2">
                        <i className="fa-solid fa-arrow-right me-2"></i>20.9%
                      </span>{" "}
                      <i className="fa-solid fa-plus me-2"></i>18.4K this week
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Top User</h4>
                    <p className="mb-0 tc-5">Lorem ipsum dolor sit</p>
                  </div>
                  <div className="ms-auto">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                        title=""
                      />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Total Spending</th>
                      <th>Total Services</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">Top Experts</h4>
                    <p className="mb-0 tc-5">Lorem ipsum dolor sit</p>
                  </div>
                  <div className="ms-auto">
                    <button type="button" className="btn-light ms-2">
                      <img
                        className="me-2"
                        src="assets/img/icon-filter.svg"
                        alt=""
                        title=""
                      />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Total Spending</th>
                      <th>Total Services</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                    <tr>
                      <td>Nico Robin</td>
                      <td>$8,759</td>
                      <td>587</td>
                      <td>jamesbond656@gmail.com</td>
                      <td>+1 456 245 6979</td>
                      <td>23 Oct, 2023</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="notifications-box"></div>
                    <div className="notifications-header">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-0">Notifications</h4>
                        <div className="dropdown notifications-dropdown-v">
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="notifications-tabs d-flex align-items-center justify-content-between mb-3">
                        <a href="#" className="active">
                          All
                        </a>
                        <a href="#">Offer</a>
                        <a href="#">Promotion</a>
                        <a href="#">Appointment</a>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="tc-5 mb-0">
                          <i className="fa-solid fa-bell me-2"></i>All
                          Notifications
                        </p>
                        <p className="tc-5 mb-0">
                          <i className="fa-solid fa-check-double me-2"></i>Mark
                          all as read
                        </p>
                      </div>
                    </div>
                    <div className="notifications-body">
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="notifications-item d-flex align-items-center justify-content-between mb-3">
                        <div className="me-3">
                          <div className="d-flex">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                              <img
                                src="assets/img/dummy-user.png"
                                alt=""
                                title=""
                              />
                            </figure>
                            <div className="flex-grow-1">
                              <p className="mb-1 tc-5">
                                Your appointment has been successfully schedule
                                with{" "}
                                <strong className="tc-6 fw-medium">
                                  Nickson John
                                </strong>
                              </p>
                              <p className="tc-7 mb-0">1 hr ago</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="dropdown notifications-dropdown-h">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card mb-4 add-stylist-campaign-popup">
                  <div className="card-body">
                    <h4 className="text-center mb-4">Add Stylist into “Campaign”</h4>
                    <form className="form-area1 row g-4 mb-3">
                      <div className="col-lg-12">
                        <input type="text" className="form-control" id="" placeholder="Search here..." />
                      </div>
                    </form>
                    <div className="add-stylist-campaign-popup-header">
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="fs-16 fw-semibold">The stylist list is below:</p>
                        </div>
                        <div className="col-lg-4 text-end">
                          <a className="fs-16 fw-semibold link"><span className="tc-1">Select All</span></a>
                        </div>
                      </div>
                    </div>
                    <div className="add-stylist-campaign-popup-body">

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                      <div className="add-stylist-campaign-user-list-item d-flex align-items-center justify-content-between mb-4">
                        <div className="me-3">
                          <div className="d-flex align-items-center">
                            <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                            <div className="flex-grow-1">
                              <p className="mb-0"><strong className="tc-6">Elisha Atif</strong></p>
                              <p className="fs-13 tc-7 mb-0">elisha.atif4509@gmail.com</p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-check form-radio">
                            <input className="form-check-input" type="radio" name="" id="" />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="">
                      <a href="#" className="btn-blue w-100 mt-3">Add Stylist</a>
                      <a href="#" className="btn-light1 w-100 mt-3">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <h4 className="text-center mb-4">Account Information</h4>
                    <form className="form-area1 row g-4">
                      <div className="col-lg-6">
                        <label for="" className="form-label">First Name</label>
                        <input type="text" className="form-control" id="" placeholder="First Name" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="" placeholder="Last Name" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Email Address</label>
                        <input type="email" className="form-control" id="" placeholder="email@gmail.com" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Phone Number</label>
                        <input type="text" className="form-control" id="" placeholder="+1 111 222 3333" />
                      </div>
                      <div className="col-lg-12">
                        <label for="" className="form-label">Password</label>
                        <div className="position-relative">
                          <input type="password" className="form-control" id="" placeholder="**************" />
                          <div className="password-icon1">
                            <i className="fa-regular fa-eye-slash"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn-blue1">Update Changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <h4 className="card-title mb-0">Expert - User (Supervision)</h4>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex align-items-center justify-content-end">
                      <button type="button" className="btn-light ms-2">
                        <img
                          className="me-2"
                          src="assets/img/icon-calendar.svg"
                          alt=""
                          title=""
                        />
                        January
                      </button>
                      <div className="form-control-group-icon ms-3">
                        <select className="form-select  w-auto">
                          <option selected>Category</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <img
                          className="me-2"
                          src="assets/img/icon-user1.svg"
                          alt=""
                          title=""
                        />
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select className="form-select  w-auto">
                          <option selected>All Stylists</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <img
                          className="me-2"
                          src="assets/img/icon-user1.svg"
                          alt=""
                          title=""
                        />
                      </div>
                      <div className="form-control-group-icon ms-3">
                        <select className="form-select  w-auto">
                          <option selected>All User</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <img
                          className="me-2"
                          src="assets/img/icon-user1.svg"
                          alt=""
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="chat mb-4">
              <div className="row">
                <div className="col-lg-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="chat-msg-header">
                            <div className="form-control-group-icon search mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here..."
                              />
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className="chat-tabs d-flex align-items-center mb-4">
                              <a href="#" className="me-2 active">All</a>
                              <a href="#" className="me-2">Unread</a>
                            </div>
                          </div>
                          <div className="chat-msg-body">

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                            <div className="chat-msg-item">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40">
                                      <img src="assets/img/dummy-user.png" alt="" title="" />
                                    </figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-1 tc-6"><strong>Linda michzaosky</strong></p>
                                      <p className="fs-12 tc-5 mb-0">#3987544 • <span className="tc-6">New Message</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <p className="fs-12 mb-0 tc-5">8:45 PM</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <p className="fs-13 mb-0">Lorem ipsum dolor sit amet, consectetur  dsfasdf dipiscing elit.</p>
                                </div>
                                <div className="">
                                  <p className="badge rounded-circle fs-12 chat-msg-badge mb-0 tc-6">02</p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-lg-7">

                          <div className="chat-area">
                            <div className="chat-area-header">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="me-3">
                                  <div className="d-flex align-items-center">
                                    <figure className="flex-shrink-0 me-2 mb-0 user-img-40"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                                    <div className="flex-grow-1">
                                      <p className="mb-0"><strong className="tc-6">Linda michzaosky</strong></p>
                                      <p className="fs-13 tc-7 mb-0">#3987544 • Aug 23, 2023</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="dropdown chat-dropdown-v">
                                    <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></a>
                                    <ul className="dropdown-menu">
                                      <li><a className="dropdown-item" href="#">Action</a></li>
                                      <li><a className="dropdown-item" href="#">Another action</a></li>
                                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="chat-area-body">
                              <div className="chat-item ">
                                <div className="d-flex">
                                  <figure className="flex-shrink-0 me-2 mb-0 user-img-35 chat-item-user offline"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                                  <div className="flex-grow-1 chat-item-chat">
                                    <div className="d-flex align-items-end">
                                      <div className="flex-grow-1 me-3">
                                        <p className="fs-13 tc-6 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae ultricies neque</p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <p className="fs-12 tc-5 mb-0">8:45 PM</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="chat-item chat-item-right-side ms-auto">
                                <div className="d-flex">
                                  <div className="flex-grow-1 chat-item-chat">
                                    <div className="d-flex align-items-end">
                                      <div className="flex-grow-1 me-3">
                                        <p className="fs-13 tc-6 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae ultricies neque</p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <p className="fs-12 tc-5 mb-0"><i className="fa-solid fa-check-double me-2"></i>8:45 PM</p>
                                      </div>
                                    </div>
                                  </div>
                                  <figure className="flex-shrink-0 ms-2 mb-0 user-img-35 chat-item-user online"><img src="assets/img/dummy-user.png" alt="" title="" /></figure>
                                </div>
                              </div>
                            </div>
                            <div className="chat-area-footer">
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <textarea className="form-control" id="" placeholder="Message"></textarea>
                                </div>
                                <a className="send flex-shrink-0 ms-2"><i className="fa-solid fa-paper-plane"></i></a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card h-100">
                    <div className="card-body admin-note">

                      <div className="admin-note-header">
                        <div className="row align-items-center mb-3">
                          <div className="col-lg-8">
                            <p className="mb-0"><strong>Admin notes</strong></p>
                            <p className="fs-12 mb-0">You have 12 notes</p>
                          </div>
                          <div className="col-lg-4 text-end">
                            <button type="button" className="btn-light-round"><i className="fa-solid fa-plus"></i></button>
                          </div>
                        </div>
                      </div>

                      <div className="admin-note-body">
                        <div className="admin-note-item">
                          <p className="fs-12 text-end mb-1"><strong>Dec 29</strong></p>
                          <p className="fs-12 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non malesuada augue. Pellentesque ultricies neque maximus maximus elementum. Aliquam vitae erat vitae urna blandit tristique eu vitae eros.</p>
                        </div>
                        <div className="admin-note-item">
                          <p className="fs-12 text-end mb-1"><strong>Dec 29</strong></p>
                          <p className="fs-12 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non malesuada augue. Pellentesque ultricies neque maximus maximus elementum. Aliquam vitae erat vitae urna blandit tristique eu vitae eros.</p>
                        </div>
                        <div className="admin-note-item">
                          <p className="fs-12 text-end mb-1"><strong>Dec 29</strong></p>
                          <p className="fs-12 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non malesuada augue. Pellentesque ultricies neque maximus maximus elementum. Aliquam vitae erat vitae urna blandit tristique eu vitae eros.</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Service Management</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Service Management</a>
                      </li>
                      <li className="breadcrumb-item active">James Bond</li>
                    </ol>
                    <h4 className="card-title mb-0">Information</h4>
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-red-light ms-2"
                    >
                      <i className="fa-solid fa-ban me-2"></i>Restrict
                    </button>
                    <button
                      type="button"
                      className="btn-red-light1 ms-2"
                    >
                      <i className="fa-regular fa-trash-can me-2"></i>Restrict
                    </button>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="me-3">
                    <div className="d-flex align-items-center">
                      <figure className="flex-shrink-0 me-3 mb-0 user-img user-img-100 online"><img className="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                      <div className="flex-grow-1">
                        <p className="fs-18 mb-0"><strong className="tc-6">Nico Robin</strong></p>
                        <p className="fs-14 tc-7 mb-0">Customer Since: 20 April 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center bg-2 px-3 py-2 fs-12 rounded-pill">
                      <strong>Active Busy Mode</strong>
                      <div className="form-check form-switch d-inline-block p-0 ms-3"><input className="form-check-input m-0" type="checkbox" role="switch" /></div>
                    </div>
                  </div>
                </div>

                <div className="tabs1 d-flex">
                  <a href="#" className="active">Personal Information</a>
                  <a href="#">Appointments</a>
                  <a href="#">Campaign</a>
                  <a href="#">Offer</a>
                  <a href="#">Packages</a>
                  <a href="#">Pending Verifications by Admin</a>
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-lg-6">

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Account Information</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>
                    <form className="form-area1 row g-4">
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id=""
                          placeholder="email@gmail.com"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="+1 111 222 3333"
                        />
                      </div>
                      <div className="col-lg-12">
                        <label for="" className="form-label">
                          Password
                        </label>
                        <div className="position-relative">
                          <input
                            type="password"
                            className="form-control"
                            id=""
                            placeholder="**************"
                          />
                          <div className="password-icon1">
                            <i className="fa-regular fa-eye-slash"></i>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">About Nickson</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <p className="fs-16">I am a barber with over 10 years of experience in the industry. I am passionate about my work and I love helping my clients to look and feel their best. I specialize in all aspects of men's grooming, including haircuts, beard trims, and hot shaves. I am also skilled in hair coloring and styling.</p>
                    <p className="fs-16">I am committed to providing my clients with a high-quality experience. I use only the best products and equipment, and I take the time to understand each client's individual needs. I am always up-to-date on the latest trends and styles, so my clients can be sure that they are getting the best possible haircut or beard trim.</p>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Availability</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Sunday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">Closed</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Monday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Tuesday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Wednesday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Thusday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Friday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Saturday</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">10:AM - 8PM</p></div>
                    </div>

                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Nickson’s Work</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <div className="ratio ratio-16x9 mb-4">
                      <iframe className="rounded-3" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowfullscreen></iframe>
                    </div>

                    <h4 className="card-title mb-2">Nickson’s Portfolio</h4>

                    <div className="row gx-3 row-cols-4">
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                      <div className="col my-2"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></div>
                    </div>


                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Location</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <form className="form-area1 row g-4">
                      <div className="col-lg-6">
                        <label for="" className="form-label">Latitude</label>
                        <input type="text" className="form-control" id="" placeholder="Latitude" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Longitude</label>
                        <input type="text" className="form-control" id="" placeholder="Longitude" />
                      </div>
                      <div className="col-lg-12">
                        <div className="ratio ratio-16x9">
                          <iframe className="rounded-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109755.73098760014!2d76.71028613795342!3d30.722150614158465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0be66ec96b%3A0xa5ff67f9527319fe!2sChandigarh!5e0!3m2!1sen!2sin!4v1701178545442!5m2!1sen!2sin" width="600" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>

              </div>

              <div className="col-lg-6">

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Profile Image</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <div className="row g-4">
                      <div className="col-lg-6">
                        <figure className="m-0"><img className="w-100" src="assets/img/dummy-img.png" alt="" title="" /></figure>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex align-items-center justify-content-center upload-img-box h-100">
                          <div className="upload-img-box-inner"><i className="fa-solid fa-file-arrow-up"></i><p className="mb-0">Upload Image</p></div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Nickson’s Services</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Fades</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">$9</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Tapers</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">$9</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Beard Trims</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">$9</p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Hair Coloring</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end">$9</p></div>
                    </div>

                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Amenities</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Parking Space</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Music</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Wi-Fi</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Selfie Station</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Child-Friendly</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Credit Cards Accepted</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>
                    <div className="row g-0 align-items-center form-control-read-only">
                      <div className="col-lg-8">
                        <p className="tc-6 m-0">Pets Friendly</p>
                      </div>
                      <div className="col-lg-4">
                        <p className="fs-16 fw-medium tc-6 m-0 text-end"></p></div>
                    </div>

                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Review</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <div className="review-boxs">

                      <div className="review-box-item">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <figure className="flex-shrink-0 me-2 mb-0 user-img user-img-40"><img className="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                              <div className="flex-grow-1">
                                <p className="mb-0"><strong className="tc-6">Jeffery Bills</strong></p>
                                <p className="fs-12 tc-5 mb-0">21-04-2023</p>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="d-flex align-items-center stars fs-16">
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                            </div>
                          </div>
                        </div>
                        <p className="fs-16 tc-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                      </div>

                      <div className="review-box-item">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <figure className="flex-shrink-0 me-2 mb-0 user-img user-img-40"><img className="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                              <div className="flex-grow-1">
                                <p className="mb-0"><strong className="tc-6">Jeffery Bills</strong></p>
                                <p className="fs-12 tc-5 mb-0">21-04-2023</p>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="d-flex align-items-center stars fs-16">
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                              <i className="fa-solid fa-star ms-2"></i>
                            </div>
                          </div>
                        </div>
                        <p className="fs-16 tc-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                      </div>

                      <div className="text-center"><a href="#" className="btn-blue">See All</a></div>

                    </div>


                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Expert feeds</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <div className="expert-feeds-boxs row">

                      <div className="expert-feeds-box-item mb-4 col-lg-6">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <figure className="flex-shrink-0 me-2 mb-0 user-img user-img-40"><img className="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                              <div className="flex-grow-1">
                                <p className="mb-0"><strong className="tc-6">Boa Hancock</strong></p>
                                <p className="fs-12 tc-5 mb-0">1 hr ago</p>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="dropdown chat-dropdown-v"><a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></a>
                              <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p className="tc-6">Our experienced barbers can help you find the perfect look for any occasion.</p>
                        <figure className="mb-3"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></figure>
                        <div className="d-flex align-items-center justify-content-between expert-feeds-box-item-footer">
                          <div className="me-3">
                            <a href="#" className="d-inline-block fw-6 me-4"><i class="fa-regular fa-heart me-1"></i> 80</a>
                            <a href="#" className="d-inline-block fw-6"><i class="fa-regular fa-message me-1"></i> 23</a>
                          </div>
                          <div className="">
                            <a href="#" className="d-inline-block fw-6"><i class="fa-solid fa-share"></i></a>
                          </div>
                        </div>
                      </div>

                      <div className="expert-feeds-box-item mb-4 col-lg-6">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <figure className="flex-shrink-0 me-2 mb-0 user-img user-img-40"><img className="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                              <div className="flex-grow-1">
                                <p className="mb-0"><strong className="tc-6">Boa Hancock</strong></p>
                                <p className="fs-12 tc-5 mb-0">1 hr ago</p>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="dropdown chat-dropdown-v"><a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></a>
                              <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p className="tc-6">Our experienced barbers can help you find the perfect look for any occasion.</p>
                        <figure className="mb-3"><img className="rounded-3 w-100" src="assets/img/portfolio-img.png" alt="" title="" /></figure>
                        <div className="d-flex align-items-center justify-content-between expert-feeds-box-item-footer">
                          <div className="me-3">
                            <a href="#" className="d-inline-block fw-6 me-4"><i class="fa-regular fa-heart me-1"></i> 80</a>
                            <a href="#" className="d-inline-block fw-6"><i class="fa-regular fa-message me-1"></i> 23</a>
                          </div>
                          <div className="">
                            <a href="#" className="d-inline-block fw-6"><i class="fa-solid fa-share"></i></a>
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-12"><a href="#" className="btn-blue">See All</a></div>
                    </div>

                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="bs-r-6 ps-3">
                        <h4 className="card-title mb-0">Stylist ID Verification</h4>
                        <p className="mb-0 tc-5">Last Updated: 23, May 2023</p>
                      </div>
                      <div className="ms-auto">
                        <a href="#" className="link"><img className="me-2" src="assets/img/icon-edit1.svg" alt="" title="" /><span>Edit</span></a>
                      </div>
                    </div>

                    <form className="form-area1 row g-4">
                      <div className="col-lg-6">
                        <label for="" className="form-label">Name</label>
                        <input type="text" className="form-control" id="" placeholder="Name" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Father Name</label>
                        <input type="text" className="form-control" id="" placeholder="Father Name" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">CNIC Number</label>
                        <input type="email" className="form-control" id="" placeholder="CNIC Number" />
                      </div>
                      <div className="col-lg-6">
                        <label for="" className="form-label">Date of Expiry</label>
                        <input type="text" className="form-control" id="" placeholder="Date of Expiry" />
                      </div>

                      <div className="col-lg-6">
                        <figure className="m-0"><img className="w-100" src="assets/img/dummy-img.png" alt="" title="" /></figure>
                      </div>
                      <div className="col-lg-6">
                        <figure className="m-0"><img className="w-100" src="assets/img/dummy-img.png" alt="" title="" /></figure>
                      </div>
                    </form>

                  </div>
                </div>

              </div>

            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div className="bs-r-6 ps-3">
                    <h4 className="card-title mb-0">User Jobs</h4>
                    <p className="mb-0 tc-5">Verification Center</p>
                  </div>
                  <div className="ms-auto">
                  </div>
                </div>

                <div className="row mb-4">
                  <div class="col-lg-4">
                    <div class="form-control-group-icon search">
                      <input type="text" class="form-control" placeholder="Search here..." value="" />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="form-control-group-icon ms-3">
                        <select class="form-select w-auto">
                          <option value="asc">All experts</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="form-control-group-icon ms-3">
                        <select class="form-select w-auto">
                          <option value="asc">Pending only</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                      <div class="form-control-group-icon ms-3">
                        <select class="form-select w-auto">
                          <option value="asc">Sort</option>
                          <option value="desc">Descending</option>
                        </select>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="job-box">
                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <figure class="mb-0 user-img user-img-40"><img class="w-100" src="assets/img/dummy-user.png" alt="" title="" /></figure>
                        </div>
                        <div class="col text-end">
                          <button type="button" class="btn-red-light">Closed</button>
                        </div>
                      </div>
                      <p className="fs-12 fw-medium tc-5 mb-1">Amanda Cooper</p>
                      <p className="fw-semibold tc-6 mb-1">Complete Facial Makeup Needed</p>
                      <p className="fs-16 tc-5 mb-2">
                        <i class="fa-regular fa-clock me-2 align-middle"></i>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                        <span className="fs-12 mx-2 align-middle">-</span>
                        <span className="fs-12 align-middle">Mon, 19 0ct</span>
                      </p>

                      <div className="row justify-content-between mb-2">
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Budget</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">$50 - $60</p>
                        </div>
                        <div class="col">
                          <p className="fs-12 tc-5 mb-1">Max Distance</p>
                          <p className="fs-14 tc-6 fw-semibold mb-1">3.8 mi</p>
                        </div>
                      </div>
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor... <a hre="#" className="tc-6 fw-medium">read more</a></p>
                    </div>
                  </div>

                </div>



              </div>
            </div>



          </div>
        </div>
      </div>
    );
  }
}
